import AppStoreScreenOne from "./AppStoreScreenOne";
import AppStoreScreenTwo from "./AppStoreScreenTwo";
import AppStoreScreenThree from "./AppStoreScreenThree";
import AppStoreHighResLogo from "./AppStoreHighResLogo";
import IphoneXLogo from "./IphoneXLogo";
import IphoneXPlane from "./IphoneXPlane";
import IphoneLogo from "./IphoneLogo";
import IphonePlane from "./IphonePlane";
import IpadLogo from "./IpadLogo";
import IpadAirFrame from "./IpadAirFrame";
import IpadProFrame from "./IpadProFrame";

const PlayScreens = {
    4001: AppStoreScreenOne,
    4002: AppStoreScreenTwo,
    4003: AppStoreScreenThree,
    4004: AppStoreHighResLogo,
    4005: IphoneXLogo,
    4006: IphoneXPlane,
    4007: IphoneLogo,
    4008: IphonePlane,
    4009: IpadLogo,
    4010: IpadAirFrame,
    4011: IpadProFrame
}
export default PlayScreens;