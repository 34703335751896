import React from 'react';
import { Link } from "react-router-dom";

const DesignToolsIndex = () => {
    return (
        <div className="automation-tools container-fluid min-vh-100 p-0 darkmode-bg">
            <div className="hero darkmode-primary py-5">
                <div className="container text-danger">
                    <h2 className="font-weight-bold">Appmaker Tools</h2>
                    <p className="text-light m-0">Bunch of productivity tools from <a href="https://www.appmaker.xyz">appmaker.xyz</a></p>
                </div>
            </div>

            <div className="design-tools-listing container mt-3">
                <h4 className="text-light">Design Tools</h4>
                <div className="tools-listing d-flex flex-wrap">
                    <Link to="/design/covidNotice/5001" className="text-decoration-none">
                        <div className="tool-list mt-3 mr-3 card border-0 bg-light">
                            <img draggable="false" src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2020/03/bb246d84-corona-notice-posters.png" class="card-img-top" alt="banner" />
                            <div class="card-body text-center py-2">
                                <h5 className="text-dark m-0 font-weight-bolder">NOTICE AMID COVID</h5>
                            </div>
                        </div>
                    </Link>
                    <Link to="/design/app-assets/app-icon" className="text-decoration-none">
                        <div className="tool-list mt-3 mr-3 card border-0 bg-light">
                            <img draggable="false" src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/9d4de178-appmaker-asssets.png" class="card-img-top" alt="banner" />
                            <div class="card-body text-center py-2">
                                <h5 className="text-dark m-0 font-weight-bolder">Appmaker assets</h5>
                            </div>
                        </div>
                    </Link>
                    <Link to="/design/banner/1001" className="text-decoration-none">
                        <div className="tool-list mt-3 mr-3 card border-0 bg-light">
                            <img draggable="false" src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/1d33229a-banner-image.png" class="card-img-top" alt="banner" />
                            <div class="card-body text-center py-2">
                                <h5 className="text-dark m-0 font-weight-bolder">Banner Maker</h5>
                            </div>
                        </div>
                    </Link>
                    <Link to="/design/grid/2001" className="text-decoration-none">
                        <div className="tool-list mt-3 mr-3 card border-0 bg-light">
                            <img draggable="false" src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/291ef05b-grid-maker.png" class="card-img-top" alt="banner" />
                            <div class="card-body text-center py-2">
                                <h5 className="text-dark m-0 font-weight-bolder">Grid Maker</h5>
                            </div>
                        </div>
                    </Link>
                    <Link to="/design/playscreen/3001" className="text-decoration-none">
                        <div className="tool-list mt-3 mr-3 card border-0 bg-light">
                            <img draggable="false" src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/42bf553e-android-screenshot-maker.png" class="card-img-top" alt="banner" />
                            <div class="card-body text-center py-2">
                                <h5 className="text-dark m-0 font-weight-bolder">Playstore assets</h5>
                            </div>
                        </div>
                    </Link>
                    <Link to="/design/appstore/4001" className="text-decoration-none">
                        <div className="tool-list mt-3 mr-3 card border-0 bg-light">
                            <img draggable="false" src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/f2d54eee-appstore-screenshot-maker.png" class="card-img-top" alt="banner" />
                            <div class="card-body text-center py-2">
                                <h5 className="text-dark m-0 font-weight-bolder">Appstore assets</h5>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            {/* <div className="sales-tools-listing container mt-5">
                <h4 className="text-white">Sales Tools</h4>
                <div className="tools-listing d-flex flex-wrap">
                    <a href="/sales-data" className="text-decoration-none">
                        <div className="tool-list mt-3 mr-3 card border-0 rounded-0 bg-danger">
                            <img draggable="false" src="https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/20758f6c-sales-data.png" class="card-img-top" alt="banner" />
                            <div class="card-body bg-warning py-2">
                                <h5 className="text-dark m-0">Sales data</h5>
                            </div>
                        </div>
                    </a>
                </div>
            </div> */}
        </div>
    );
}

export default DesignToolsIndex;