import * as React from "react";

const BannerThree = ({ bgcolor, title1, btncolor, txtcolor, CTAText }) => {
    return (
        <svg id="diagram" width="360" height="200" viewBox="0 0 1080 608">
            <defs>
                <filter id="This_is_a_title" x="49" y="238" width="982" height="130" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity="0.38" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="button-outline" x="348" y="386" width="384" height="107" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur-2" />
                    <feFlood flood-opacity="0.529" />
                    <feComposite operator="in" in2="blur-2" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="BANNER-3" transform="translate(-5992 -3859)">
                <rect id="BG" width="1080" height="600" transform="translate(5992 3859)" fill={bgcolor} />
                <g transform="matrix(1, 0, 0, 1, 5992, 3859)" filter="url(#This_is_a_title)">
                    <text id="This_is_a_title-2" data-name="This is a title" transform="translate(58 244)" fill={txtcolor} font-size="80" font-family="Poppins-SemiBold, Poppins" font-weight="600" text-anchor="middle"><tspan x="460" y="84">{title1}</tspan></text>
                </g>
                <g id="BUTTON">
                    <g transform="matrix(1, 0, 0, 1, 5992, 3859)" filter="url(#button-outline)">
                        <rect id="button-outline-2" data-name="button-outline" width="366" height="89" rx="10" transform="translate(357 392)" fill={btncolor} />
                    </g>
                    <text id="SHOP_NOW" data-name="SHOP NOW" fill={txtcolor} transform="translate(6532 4311)" font-size="45" font-family="Poppins-SemiBold, Poppins" font-weight="600" text-anchor="middle"><tspan x="0" y="0">{CTAText}</tspan></text>
                </g>
            </g>
        </svg>
    );
};
BannerThree.formSchema = {
    type: "object",

    properties: {
        title1: {
            type: "string",
            title: "Main title",
            default: "Catch the big deal"
        },
        CTAText: {
            type: "string",
            title: "Button text",
            default: "SHOP NOW"
        },
        bgcolor: {
            type: "string",
            title: "Background color (Color name/Hex code)",
            default: "grey"
        },
        txtcolor: {
            type: "string",
            title: "Text color (Color name/Hex code)",
            default: "#fff"
        },
        btncolor: {
            type: "string",
            title: "Button color (Color name/Hex code)",
            default: "#000"
        }
    }
};
export default BannerThree;