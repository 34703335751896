import * as React from "react";

const CovidBannerTwo = ({ title2, bgColor, subtext }) => {
    return (
        <svg id="diagram" width="360" height="200" viewBox="0 0 1080 536">
            <g id="CovidBanner2" transform="translate(-705 -500)">
                <rect id="Rectangle_579" data-name="Rectangle 579" width="1080" height="536.329" transform="translate(705 500)" fill={bgColor} />
                <g id="Group_219" data-name="Group 219" transform="translate(34.457)" opacity="0.03">
                    <path id="Path_3940" data-name="Path 3940" d="M1603.785,714.516c45.5,4.029,105.1,10.522,126.907,36.94,21.677,26.418,5.546,72.873-17.013,108.918s-51.8,61.68-78.513,88.993c-26.843,27.2-51.417,56.082-82.672,63.918s-69.314-5.373-115.691-9.851c-46.5-4.365-101.45,0-141.526-19.253-39.95-19.254-65.155-62.127-72.465-107.239s3.277-92.463,28.23-130.971c25.079-38.62,64.651-68.172,107.877-73.209,43.227-5.15,89.982,14.44,129.05,25.074S1558.29,710.374,1603.785,714.516Z" />
                </g>
                <g id="Group_240" data-name="Group 240">
                    <path id="Path_3941" data-name="Path 3941" d="M1488.271,594.936c.54,12.748-10,13.948-22.569,12.855l-.7-7.33,3.025-4.012,5.177-1.8Z" fill="#4c77b0" fill-rule="evenodd" />
                    <path id="Path_3942" data-name="Path 3942" d="M1480.3,719.993c5.671,5.482,7.642,10.858,16.382,17.048l-5.052,7.52c-11.879-6.728-27.862-12.8-43.168-25.924C1460.421,707.876,1467.224,707.348,1480.3,719.993Z" fill="#f0c17d" fill-rule="evenodd" />
                    <g id="Group_220" data-name="Group 220">
                        <path id="Path_3943" data-name="Path 3943" d="M1438.436,624.235l-2.681,15.077,17.962,14.245,3.613-3.424c4.4-12.839.642-11.536,4.879-27.074l-19.224-10.581C1441.906,621.572,1440,617.774,1438.436,624.235Z" fill="#f0c17d" fill-rule="evenodd" />
                        <path id="Path_3944" data-name="Path 3944" d="M1474.016,620.546a2.268,2.268,0,0,0,.887,2.693,1.9,1.9,0,0,1-1.349,1.995c-3.687-.458-2.414,2-2.127,2.044,2.676.4,2.293,1.367,1.791,2.2-.764.144-1.111.435-1.206.809-.3,1.162,1.745,3.571,0,5.532-2.106,2.359-12.9-3.21-17.925-5.78-4.163-2.128-2.731-4.991-2.194-9.508-2.4.274-4.229-.474-4.274-4.209-3.044-4.46-4.039-8.055-5.938-13.086,0,0,8.161-19.764,8.652-19.878s15.953,2.175,15.953,2.175c1.817,3.017,4.812,5.83,6.7,9.344,2.619,4.868,2.643,7.781,3.237,10.1a4.941,4.941,0,0,1-.247,3.855,4.351,4.351,0,0,0-.57,1.47c-.081,3.809,3.553,7.2,3.33,8.256C1478.555,619.424,1476.759,620.894,1474.016,620.546Z" fill="#ffdba7" fill-rule="evenodd" />
                        <path id="Path_3945" data-name="Path 3945" d="M1458.972,608.559c-.055,1.44-1.384,4.1-1.359,7-1.821-1.891-2.265-3.327-3.766-4.884-2.111-1.978-4.8-3.9-6.817-3.1-2.315.919-1.135,5.188.727,8.138a5.258,5.258,0,0,1,.715,4.367c-.842,2.432-3.319,1.306-5.539,3.715-1.812,1.964-4.517,4.413-5.35,5.759-.425-3.842-.294-7.476-2.72-11.155a22.991,22.991,0,0,1-2.935-7.543,22.02,22.02,0,0,1,13.065-25.4c11.392-4.627,24.575.394,27.827,9.09-1.9-.093-5.514.9-9.112,2.486a14.464,14.464,0,0,0-3.52,12.965Z" fill="#632404" fill-rule="evenodd" />
                    </g>
                    <path id="Path_3946" data-name="Path 3946" d="M1507.2,944.467c-6.976,6.113-20.786,3.842-29.087,4.239-2.332,2.219-7.053,1.6-8.807,1.5-3.877-.221-5.22-9.428-5.4-15.074-.148-4.533-1.758-7.848-2.964-12.678l14.534-6.188c1.121,8.065.969,10.962,3.764,14.065,5.763,6.4,14.91,5.6,20.992,4.978C1509.8,934.334,1511.829,940.411,1507.2,944.467Z" fill="#1f1c1d" fill-rule="evenodd" />
                    <g id="Group_221" data-name="Group 221">
                        <path id="Path_3947" data-name="Path 3947" d="M1409.932,952.668c-9.117,1.708-19.844-7.279-27.189-11.168-3.139.718-6.884-2.218-8.341-3.2-3.223-2.168.311-10.774,3.029-15.726,2.183-3.975,2.486-7.647,3.909-12.421L1397,912.241c-3.143,7.51-4.749,9.922-3.925,14.015,1.7,8.442,9.977,12.414,15.526,14.98C1417.331,945.275,1415.982,951.539,1409.932,952.668Z" fill="#1f1c1d" fill-rule="evenodd" />
                        <path id="Path_3948" data-name="Path 3948" d="M1416.183,779.62c2.8-26.393,15.85-26.66,24.009-43.948l23.9,8.188-2.539,42.515-6.461,8.86-15.066,44.339-1.193,2.58-43.718,87.72-20.937-5.6,21.355-68.518,16.794-26.837C1416.536,813.139,1414.731,793.231,1416.183,779.62Z" fill="#072c5e" fill-rule="evenodd" />
                    </g>
                    <path id="Path_3949" data-name="Path 3949" d="M1424.417,792.372c-11.035-24.138.058-31.016-1.729-50.049l42.7,2.118,1.51,30.632-1.047,10.911,9.624,45.83.289,2.829,7.071,97.753-20.868,5.847-16.534-69.837.78-31.651C1441.8,821.036,1430.1,804.823,1424.417,792.372Z" fill="#0f4283" fill-rule="evenodd" />
                    <path id="Path_3950" data-name="Path 3950" d="M1418.415,719.371c.79,4.734,1.615,28.952,1.451,35.507,16.765,2.078,30.417,3.558,47.047,2.111-.586-4.71-2.011-19.483-.19-29.423,7.246-39.535,10.136-64-1.6-78.85a30.915,30.915,0,0,0-6.055-4.233c-.511-.461-2.8,3.2-3.346,2.723-15.475,1.31-17.574-11.97-19.029-10.988-10.416,7.036-19.076,8.135-22.219,17.867C1409.007,671.013,1418.349,700.118,1418.415,719.371Z" fill="#6492d0" fill-rule="evenodd" />
                    <path id="Path_3951" data-name="Path 3951" d="M1432.017,612.221a31.211,31.211,0,0,1-1.23-5.619c-1.381-11.142,6.612-21.809,15.894-24.068,12.413-3.016,20.129,1.23,24.771,7.855a27.381,27.381,0,0,1,2.825,5.038c-7.711,2.95-14.949,5.856-22.781,8.719C1444.622,606.659,1437.584,607.858,1432.017,612.221Z" fill="#6492d0" fill-rule="evenodd" />
                    <g id="Group_222" data-name="Group 222">
                        <path id="Path_3952" data-name="Path 3952" d="M1411.581,688.492c1.109,6.812,2.835,24.328,5.467,32.005.162.468,16.41,16.751,20.018-3.724,1.168-1.788-1.8-12.786-.693-38.657.911-21.355-10.995-28.454-18.459-24.9C1411.006,656.5,1408.384,668.868,1411.581,688.492Z" fill="#ffdba7" fill-rule="evenodd" />
                        <path id="Path_3953" data-name="Path 3953" d="M1410.239,688.586c7.34-5.456,20.366-4.457,26.909-1.276.084-2.636.2-5.706.353-9.291,1-23.413-1.631-35.333-17.311-31.631C1401.285,651.229,1408.768,679.556,1410.239,688.586Z" fill="#76a5e3" fill-rule="evenodd" />
                    </g>
                    <path id="Path_3954" data-name="Path 3954" d="M1421.248,723.755c18.585,8.91,32.382,10.223,52.394,16.827,2.225-6.22,2.283-7.47,4.333-10.189,0,0-31.142-17.559-46.411-26.3C1420.312,697.656,1408.711,717.46,1421.248,723.755Z" fill="#ffdba7" fill-rule="evenodd" />
                    <path id="Path_3955" data-name="Path 3955" d="M1473.919,594.675c6.423-.2,8.564.027,14.358.184-6.868,8.028-20.139,6.618-30.048,6.611C1461.207,599.055,1469.294,594.775,1473.919,594.675Z" fill="#76a5e3" fill-rule="evenodd" />
                    <g id="Group_224" data-name="Group 224">
                        <path id="Path_3956" data-name="Path 3956" d="M1456.512,647.193a173.946,173.946,0,0,1,3.835,20.326c.3,2.539.3,2.816-2.419,3.133-.431.05-.918.107-1.445.174l.249,1.089c.54-.07,1.009-.124,1.422-.17,3.915-.461,4.047-.478,3.4-4.327a154.848,154.848,0,0,0-4.056-20.332Z" fill="#5782bc" fill-rule="evenodd" />
                        <g id="Group_223" data-name="Group 223">
                            <path id="Path_3957" data-name="Path 3957" d="M1458.018,667.1a.862.862,0,0,0-.753,1.122,1.255,1.255,0,0,0,1.3.9.86.86,0,0,0,.753-1.123A1.259,1.259,0,0,0,1458.018,667.1Z" fill="#8bb7f0" fill-rule="evenodd" />
                            <path id="Path_3958" data-name="Path 3958" d="M1456.943,660.366a.863.863,0,0,0-.754,1.126,1.254,1.254,0,0,0,1.3.9.86.86,0,0,0,.754-1.122A1.261,1.261,0,0,0,1456.943,660.366Z" fill="#8bb7f0" fill-rule="evenodd" />
                            <path id="Path_3959" data-name="Path 3959" d="M1455.817,653.828a.86.86,0,0,0-.753,1.122,1.26,1.26,0,0,0,1.3.9.862.862,0,0,0,.754-1.123A1.261,1.261,0,0,0,1455.817,653.828Z" fill="#8bb7f0" fill-rule="evenodd" />
                        </g>
                    </g>
                    <path id="Path_3960" data-name="Path 3960" d="M1436.034,633.9c4.295,5.87,12.689,11.4,19.753,13.083a.614.614,0,0,1-.1.414,26.841,26.841,0,0,0-5.956,10.4c-5.483-5.66-13.84-12.2-15.425-20.587Z" fill="#76a5e3" fill-rule="evenodd" />
                    <path id="Path_3961" data-name="Path 3961" d="M1459.808,638.006c-.049,3.8-.719,6.086-4.033,9-.045.04-.219.194.017.277,2.449,1.276,4.572,2.389,7.071,7.41a19.691,19.691,0,0,0-.679-10.918Z" fill="#76a5e3" fill-rule="evenodd" />
                    <g id="Group_239" data-name="Group 239">
                        <g id="Group_237" data-name="Group 237">
                            <path id="Path_3962" data-name="Path 3962" d="M1490.618,746.222l4.995-3.488L1622.82,924.863l93.543-65.336,3.463,4.958-98.536,68.825Z" fill="#636363" fill-rule="evenodd" />
                            <g id="Group_228" data-name="Group 228">
                                <g id="Group_225" data-name="Group 225">
                                    <rect id="Rectangle_580" data-name="Rectangle 580" width="99.177" height="84.142" transform="translate(1521.327 780.225) rotate(-34.933)" fill="#d8bd76" />
                                    <rect id="Rectangle_581" data-name="Rectangle 581" width="99.341" height="18.554" transform="translate(1521.327 780.225) rotate(-34.933)" fill="#c4aa68" />
                                    <rect id="Rectangle_582" data-name="Rectangle 582" width="104.071" height="14.905" transform="translate(1518.565 780.542) rotate(-34.932)" fill="#e7d691" />
                                </g>
                                <g id="Group_227" data-name="Group 227">
                                    <rect id="Rectangle_583" data-name="Rectangle 583" width="33.78" height="15.325" transform="translate(1581.975 762.822) rotate(-34.933)" fill="#dfe9f2" />
                                    <g id="Group_226" data-name="Group 226">
                                        <rect id="Rectangle_584" data-name="Rectangle 584" width="3.59" height="11.398" transform="translate(1585.125 763.015) rotate(-34.912)" fill="#818286" />
                                        <rect id="Rectangle_585" data-name="Rectangle 585" width="3.589" height="11.401" transform="translate(1590.378 759.344) rotate(-34.92)" fill="#818286" />
                                        <rect id="Rectangle_586" data-name="Rectangle 586" width="3.59" height="11.4" transform="translate(1595.804 755.558) rotate(-34.965)" fill="#818286" />
                                        <rect id="Rectangle_587" data-name="Rectangle 587" width="3.589" height="11.401" transform="translate(1600.781 752.078) rotate(-34.92)" fill="#818286" />
                                        <rect id="Rectangle_588" data-name="Rectangle 588" width="3.589" height="11.4" transform="matrix(0.82, -0.573, 0.573, 0.82, 1605.825, 748.557)" fill="#818286" />
                                    </g>
                                </g>
                            </g>
                            <g id="Group_232" data-name="Group 232">
                                <g id="Group_229" data-name="Group 229">
                                    <rect id="Rectangle_589" data-name="Rectangle 589" width="108.954" height="92.44" transform="translate(1569.905 849.354) rotate(-34.934)" fill="#d8bd76" />
                                    <rect id="Rectangle_590" data-name="Rectangle 590" width="109.136" height="20.384" transform="matrix(0.82, -0.573, 0.573, 0.82, 1569.905, 849.353)" fill="#c4aa68" />
                                    <rect id="Rectangle_591" data-name="Rectangle 591" width="114.331" height="16.374" transform="translate(1566.871 849.704) rotate(-34.932)" fill="#e7d691" />
                                </g>
                                <g id="Group_231" data-name="Group 231">
                                    <rect id="Rectangle_592" data-name="Rectangle 592" width="37.112" height="16.836" transform="translate(1636.531 830.233) rotate(-34.928)" fill="#dfe9f2" />
                                    <g id="Group_230" data-name="Group 230">
                                        <rect id="Rectangle_593" data-name="Rectangle 593" width="3.944" height="12.524" transform="translate(1639.987 830.454) rotate(-34.986)" fill="#818286" />
                                        <rect id="Rectangle_594" data-name="Rectangle 594" width="3.944" height="12.523" transform="translate(1645.763 826.419) rotate(-34.941)" fill="#818286" />
                                        <rect id="Rectangle_595" data-name="Rectangle 595" width="3.944" height="12.523" transform="translate(1651.727 822.252) rotate(-34.931)" fill="#818286" />
                                        <rect id="Rectangle_596" data-name="Rectangle 596" width="3.944" height="12.524" transform="translate(1657.193 818.433) rotate(-34.928)" fill="#818286" />
                                        <rect id="Rectangle_597" data-name="Rectangle 597" width="3.943" height="12.523" transform="matrix(0.82, -0.573, 0.573, 0.82, 1662.734, 814.566)" fill="#818286" />
                                    </g>
                                </g>
                            </g>
                            <g id="Group_236" data-name="Group 236">
                                <g id="Group_233" data-name="Group 233">
                                    <rect id="Rectangle_598" data-name="Rectangle 598" width="71.042" height="34.094" transform="translate(1500.584 751.738) rotate(-34.932)" fill="#d8bd76" />
                                    <rect id="Rectangle_599" data-name="Rectangle 599" width="71.159" height="13.291" transform="translate(1500.584 751.737) rotate(-34.934)" fill="#c4aa68" />
                                    <rect id="Rectangle_600" data-name="Rectangle 600" width="74.547" height="10.677" transform="translate(1498.605 751.965) rotate(-34.932)" fill="#e7d691" />
                                </g>
                                <g id="Group_235" data-name="Group 235">
                                    <rect id="Rectangle_601" data-name="Rectangle 601" width="24.199" height="10.979" transform="translate(1544.025 739.273) rotate(-34.934)" fill="#dfe9f2" />
                                    <g id="Group_234" data-name="Group 234">
                                        <rect id="Rectangle_602" data-name="Rectangle 602" width="2.572" height="8.165" transform="matrix(0.82, -0.573, 0.573, 0.82, 1546.281, 739.413)" fill="#818286" />
                                        <rect id="Rectangle_603" data-name="Rectangle 603" width="2.572" height="8.166" transform="translate(1550.044 736.785) rotate(-34.952)" fill="#818286" />
                                        <rect id="Rectangle_604" data-name="Rectangle 604" width="2.572" height="8.166" transform="matrix(0.82, -0.573, 0.573, 0.82, 1553.933, 734.068)" fill="#818286" />
                                        <rect id="Rectangle_605" data-name="Rectangle 605" width="2.572" height="8.166" transform="matrix(0.82, -0.573, 0.573, 0.82, 1557.495, 731.58)" fill="#818286" />
                                        <rect id="Rectangle_606" data-name="Rectangle 606" width="2.571" height="8.167" transform="translate(1561.112 729.052) rotate(-34.89)" fill="#818286" />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g id="Group_238" data-name="Group 238">
                            <path id="Path_3963" data-name="Path 3963" d="M1626.356,954.783a25.721,25.721,0,1,0-25.723-25.721A25.772,25.772,0,0,0,1626.356,954.783Z" fill-rule="evenodd" />
                            <path id="Path_3964" data-name="Path 3964" d="M1626.356,941.149a12.085,12.085,0,1,0-12.085-12.087A12.111,12.111,0,0,0,1626.356,941.149Z" fill="#aaafa8" fill-rule="evenodd" />
                        </g>
                    </g>
                    <path id="Path_3965" data-name="Path 3965" d="M1487.3,745.654l14.193,1.036,3.737-9.722-.434-3.02-.109-2.2-9.914-5.539-11.144.768-3.738,9.722Z" fill="#f0c17d" fill-rule="evenodd" />
                    <path id="Path_3966" data-name="Path 3966" d="M1480.014,747.468l14.23-.15,2.913-10-.682-2.97-.292-2.188-10.34-4.691-11.041,1.691-2.915,10Z" fill="#ffdba7" fill-rule="evenodd" />
                    <path id="Path_3967" data-name="Path 3967" d="M1492.451,758.255a12.833,12.833,0,1,0-12.834-12.835A12.86,12.86,0,0,0,1492.451,758.255Z" fill="#636363" fill-rule="evenodd" />
                </g>
                <text id="We_re_temporarily_suspending_services." data-name="We&apos;re temporarily suspending services." transform="translate(790.999 834.886)" fill="#1c1c1c" font-size="26.48" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">{subtext}</tspan></text>
                <text id="Temporarily_halted." data-name="Temporarily halted." transform="translate(790.999 752.38)" fill="#0f4283" font-size="46.48" font-family="Poppins-Bold, Poppins" font-weight="700"><tspan x="0" y="0">{title2}</tspan></text>
            </g>
        </svg >
    );
};
CovidBannerTwo.formSchema = {
    type: "object",

    properties: {
        title2: {
            type: "string",
            title: "Main title",
            default: "Temporarily halted.",
        },
        subtext: {
            type: "string",
            title: "Discription",
            default: "We're temporarily suspending services.",
        },
        bgColor: {
            type: "string",
            title: "Background Color",
            default: "#EEF7FF",
        },
    }
};
export default CovidBannerTwo;