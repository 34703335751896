// @flow strict

import React, { useState, useEffect } from "react";
import "./style.css";
import PluginItem from "../pluginSupport/components/PluginItem";
import {
  pluginListListener
} from "../../../lib/pluginListHelper";
import CardList from "./components/CardList";
import options from "./utils/options";

const PluginSupport = () => {
  return (
    <div class="ui">
      <nav class="navbar app">Appmaker Plugin process</nav>
      <nav class="navbar board">Board bar</nav>
      <div class="lists">
        {Object.values(options).map(item => (
          <CardList
            key={`card-item-${item.value}`}
            title={item.label}
            status={item.value}
            pluginListListener={pluginListListener}
          />
        ))}
      </div>
    </div>
  );
};

export default PluginSupport;
