import firebase from "firebase";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyByvIKH_Hnhmn0393en2Csr6liXh0CumQU",
  authDomain: "appmaker-tools.firebaseapp.com",
  databaseURL: "https://appmaker-tools.firebaseio.com",
  projectId: "appmaker-tools",
  storageBucket: "appmaker-tools.appspot.com",
  messagingSenderId: "103872567320",
  appId: "1:103872567320:web:0faa2c7ed8772e663e0b6d",
  measurementId: "G-S8RME4J7B0",
  scopes: ["email", "profile", "https://www.googleapis.com/auth/calendar"]
};
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const db = app.firestore();

export { db };
app.analytics();

export default app;
