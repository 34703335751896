export const INITIAL = "/";
export const MANAGE = "/manage";
export const SALES_DATA = "/internal";
export const INTERNAL = "/internal";
export const SALES_DATA_MAIN = `${SALES_DATA}/:formId?`;
export const DESIGN_TOOLS = "/design";
export const ScreenShort = "/ScreenShort";
// export const in
export const PLUGIN_SUPPORT = "/pluginSupport";
export const PROCESS_LIST = `${PLUGIN_SUPPORT}/process-list/:status?/:projectId?`;
export const VIEW_LIST = `${PLUGIN_SUPPORT}/view-list`;
export const VIEW_PLUGIN_PROJECT = `${PLUGIN_SUPPORT}/project/:projectId`;
export const processPlugin = projectId => `${PLUGIN_SUPPORT}/process-list/to-process/${projectId}`
export const viewPlugin = projectId => `${PLUGIN_SUPPORT}/project/${projectId}`