import firebase from "firebase";
import { BASE_URL } from "../constants/config";

const proxyFetch = (url, options) => {
  return fetch(`https://proxy.appmaker.xyz/${url}`, {
    ...options,
    mode: "cors"
  });
};
const addAuthHeaders = (options, idToken) => {
  const headers = options.headers || {};
  headers.idToken = idToken;
  return {
    ...options,
    headers
  };
};

const authFetch = (url, options) => {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then(idToken => {
      return fetch(url, {
        ...addAuthHeaders(options, idToken),
        mode: "cors"
      });
    });
};
const api = {
  formList: () =>
    authFetch(`${BASE_URL}/forms/list`, { mode: "cors" }).then(res =>
      res.json()
    ),
  formById: formId =>
    authFetch(`${BASE_URL}/forms/get/${formId}`, { mode: "cors" }).then(res =>
      res.json()
    ),
  getPluginListByProjectId: projectId =>
    authFetch(`${BASE_URL}/internalApi/get-plugins-by-project-id`, {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ projectId })
    }).then(res => res.json()),
  getSupportedPluginListByProjectId: projectId =>
    authFetch(`${BASE_URL}/internalApi/plugins-by-project-id-suppored/${projectId}`, {
      mode: "cors"
    }).then(res => res.json()),
  processPluginList: projectId =>
    authFetch(`${BASE_URL}/internalApi/process-plugin-list/${projectId}`, {
      mode: "cors"
    }).then(res => res.json())
};
export { proxyFetch, authFetch, api };
