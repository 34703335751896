// @flow strict

import React, { useEffect } from "react";
import { db } from "../../lib/firebase";
const COLLECTION_NAME = "woocommerceEvents";
const getEvents = () =>
  db
    .collection(COLLECTION_NAME)
    // .limit(10)
    .get()
    .then(snapshot => {
      if (snapshot.empty) {
        return false;
      }
      const documents = [];
      snapshot.forEach(doc => {
        documents.push(doc.data());
      });
      return documents;
    });
const ScreenShort = () => {
  const onReceiveMessage = e => {
    if (e.data.type == "recordedEvent") {
      // db.collection(COLLECTION_NAME).add(e.data);
      // console.log(e)
    }
    console.log(e);
  };
  const sendMessage = serializedData => {
    _frame.contentWindow.postMessage(serializedData, "*");
  };
  useEffect(() => {
    window.addEventListener("message", onReceiveMessage);

    return () => window.removeEventListener("message", onReceiveMessage, false);
  }, []);
  let _frame;
  const startPlay = () => {
    getEvents().then(actions => {
      console.log(actions[0]);
      sendMessage({ type: "playEvent", value: { event: actions[0].value } });
    });
  };
  return (
    <div className="row">
      <div className="col-6">
        <iframe
          title="App"
          ref={el => {
            _frame = el;
          }}
          src="https://appetize.io/embed/ba3hj7qduzmbqp44e3w53e4m2r?device=ipad&orientation=portrait&scale=75&xdocMsg=true&deviceColor=white&debug=true&screenOnly=false&autoplay=true&record=true"
          width="416px"
          height="870px"
          frameborder="0"
          scrolling="no"
        ></iframe>
      </div>
      <div className="col-4">
        <button
          className="btn btn-primary"
          onClick={() => {
            _frame.contentWindow.postMessage(
              { type: "mouseclick", x: 12, y: 50 },
              "*"
            );
          }}
        >
          Open Menu Screensort
        </button>
        <button className="btn btn-primary mt-5" onClick={startPlay}>
          startPlay
        </button>
      </div>
    </div>
  );
};

export default ScreenShort;
