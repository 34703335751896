// @flow strict

import React, { useState, useEffect } from "react";
import Form from "react-jsonschema-form";
import withAuth from "../hoc/withAuth";
import firebase from "firebase";
import * as apiHelper from "./helper";
type Props = {||};
const downloadFile = ({ filename, data, type }) => {
  var blob = new Blob([data], { type });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
};
const log = type => console.log.bind(console, type);
const form = {
  title: "Create firebase Project",
  type: "object",
  properties: {
    packageName: {
      type: "string",
      title: "Package Name",
      default: ""
    },
    id: {
      type: "number",
      title: "Project ID",
      default: Math.round(1000 * Math.random())
    },
    name: { type: "string", title: "Project Name", default: "" }
  }
};

function Firebase(props: Props) {
  const [response, setReponse] = useState([]);
  const [message, setMessage] = useState([]);
  const [androidApp, setAndroidApp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formLoadError, setformLoadError] = useState(false);
  // const [response, setResponse] = useState({});
  const [formDataState, setFormData] = useState({});
  const [iosApp, setiosApp] = useState([]);
  const [projectCreated, setProjectCreated] = useState(null);
  const [project, setProject] = useState(null);
  const formSubmit = ({ formData }, e) => {
    e.preventDefault();
    setFormData(formData);
    setProject({
      id: Math.round(1000 * Math.random()),
      ...formData
    });
    setLoading(true);
  };
  const addResponse = newReponse =>
    setReponse(prevArray => [newReponse, ...prevArray]);
  const addMessage = newReponse =>
    setMessage(prevArray => [newReponse, ...prevArray]);
  const [accessToken, setAccessToken] = useState(null);
  const LoginWithGoogle = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/cloud-platform");
    provider.addScope("https://www.googleapis.com/auth/analytics.readonly");
    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(function(result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        console.log(token);
        // localStorage.setItem("accessToken", token);
        setAccessToken(token);
      })
      .catch(console.log);
  };
  const loadData = () => {
    // console.log("load");
    if (accessToken) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + accessToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(
        "https://firebase.googleapis.com/v1beta1/availableProjects",
        requestOptions
      )
        .then(response => response.json())
        .then(res => {
          !res.error ? addResponse(res) : LoginWithGoogle();
        })
        .catch(error => LoginWithGoogle());
    } else {
      LoginWithGoogle();
    }
  };
  useEffect(() => {
    console.log("userEdde");
    if (accessToken === null) LoginWithGoogle();
    if (project)
      apiHelper
        .createGoogleCloudProject({
          accessToken,
          projectId: project.id,
          projectName: project.name
        })
        .then(res => {
          addMessage("Project Create Started");
          addResponse(res);
          checkStatus({
            operationId: res.name,
            isFirebase: false,
            cb: res => {
              addMessage("Project Create Completed");
              setProjectCreated(res.response);
              const projectId = res.response.projectId.replace("projects/", "");
              connectFirebase(projectId);
            }
          });
        });
  }, [project]);
  // const processResponse = data =>
  const connectFirebase = async projectId => {
    const res = await apiHelper.addFirebaseProject({
      accessToken,
      projectId
    });
    const downloadConfigFile = async ({ platform, appId }) => {
      const configFile = await apiHelper.downloadConfigFile({
        platform,
        accessToken,
        projectId,
        appId
      });
      downloadFile({
        filename: configFile.configFilename,
        data: atob(configFile.configFileContents),
        type: platform === "android" ? "application/json" : "application/plist"
      });
    };
    const addAndroidApp = async () => {
      const resp = await apiHelper.addAndroidtoFirebaseProject({
        accessToken,
        projectId: projectId,
        projectName: project.name,
        packageName: project.packageName
      });
      addMessage("Android App Create Started");
      checkStatus({
        operationId: resp.name,
        isFirebase: true,
        cb: res => {
          addResponse(res);
          addMessage("Android App Create Completed");
          setAndroidApp(res.response);
          downloadConfigFile({
            platform: "android",
            appId: res.response.appId
          });
        }
      });
    };
    const addiOSApp = async () => {
      const resp = await apiHelper.addiOStoFirebaseProject({
        accessToken,
        projectId: projectId,
        projectName: project.name,
        bundleId: project.packageName
      });
      addMessage("iOS App Create Started");

      checkStatus({
        operationId: resp.name,
        isFirebase: true,
        cb: res => {
          addResponse(res);
          addMessage("iOS App Create Completed");

          setAndroidApp(res.response);
          downloadConfigFile({
            platform: "ios",
            appId: res.response.appId
          });
        }
      });
    };

    checkStatus({
      operationId: res.name,
      isFirebase: true,
      cb: async res => {
        addResponse(res);
        await addAndroidApp();
        await addiOSApp();
        setLoading(false)
      }
    });
  };
  const checkStatus = ({ operationId, isFirebase, cb }) => {
    apiHelper
      .checkProjectOperations({
        accessToken,
        operationId,
        isFirebase
      })
      .then(res => {
        console.log(res);
        addMessage("checking status");
        addResponse(res);
        if (!res.done) {
          setTimeout(() => checkStatus({ operationId, isFirebase, cb }), 2000);
        } else {
          cb(res);
        }
      });
  };
  return (
    <div className="">
      <div className="p-4">
        <div className="container">
          <Form
            schema={form}
            onSubmit={formSubmit}
            // uiSchema={this.state.uiSchema}
            onError={log("errors")}
          />

          {loading === true ? "submitting..." : null}
          {formLoadError && (
            <div className="alert alert-danger my-3" role="alert">
              Error on loading form
            </div>
          )}
          {form === undefined && (
            <div className="d-block">
              <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-8">
          <ul>
            {message.map(item => (
              <li>
                <pre>{JSON.stringify(item, null, 2)}</pre>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default withAuth(Firebase);
