export const FIREBASE_API_BASE_URL = "https://firebase.googleapis.com/v1beta1";
export const GCP_API_BASE_URL =
  "https://cloudresourcemanager.googleapis.com/v1";
export const createGoogleCloudProject = ({
  accessToken,
  projectId,
  projectName
}) =>
  fetch(`${GCP_API_BASE_URL}/projects/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({
      projectId: `appmaker-${projectId}`,
      name: projectName,
      labels: { mylabel: "created-by-appmaker" }
    }),
    redirect: "follow"
  }).then(response => response.json());
export const checkProjectOperations = ({
  accessToken,
  operationId,
  isFirebase
}) =>
  fetch(
    `${isFirebase ? FIREBASE_API_BASE_URL : GCP_API_BASE_URL}/${operationId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      redirect: "follow"
    }
  ).then(response => response.json());

export const getAvailableProjects = ({ accessToken }) =>
  fetch(`${FIREBASE_API_BASE_URL}/availableProjects`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    redirect: "follow"
  }).then(response => response.json());
export const addFirebaseProject = ({ accessToken, projectId }) =>
  fetch(`${FIREBASE_API_BASE_URL}/projects/${projectId}:addFirebase`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({}),
    redirect: "follow"
  }).then(response => response.json());
export const addAndroidtoFirebaseProject = ({
  accessToken,
  projectId,
  projectName,
  packageName
}) =>
  fetch(`${FIREBASE_API_BASE_URL}/projects/${projectId}/androidApps/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({
      displayName: `${projectName} Android App`,
      packageName
    }),
    redirect: "follow"
  }).then(response => response.json());
export const addiOStoFirebaseProject = ({
  accessToken,
  projectId,
  projectName,
  bundleId
}) =>
  fetch(`${FIREBASE_API_BASE_URL}/projects/${projectId}/iosApps/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({
      displayName: `${projectName} iOS App`,
      bundleId
    }),
    redirect: "follow"
  }).then(response => response.json());
export const downloadConfigFile = ({
  platform,
  accessToken,
  projectId,
  appId
}) =>
  fetch(
    `${FIREBASE_API_BASE_URL}/projects/${projectId}/${
      platform === "android" ? "androidApps" : "iosApps"
    }/${appId}/config`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      redirect: "follow"
    }
  ).then(response => response.json());
// export const deleteAllProjects
