import * as React from "react";

const AppIcon = ({ image, bgcolor, bradius }) => {
    return (
        <svg id="diagram" width="200" height="200" viewBox="0 0 512 512">
            <defs>
                <pattern id="pattern" preserveAspectRatio="xMidYMid meet" width="100%" height="100%" viewBox="0 0 342 342">
                    <image preserveAspectRatio="xMidYMid meet" width="342" height="342" href={image || 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/08/4e401062-logo.png'} />
                </pattern>
            </defs>
            <g id="app-icon" transform="translate(8168 -2383)">
                <rect id="app-icon-bg" width="512" height="512" rx={bradius} transform="translate(-8168 2383)" fill={bgcolor} />
                <rect id="logo_container" width="342" height="342" transform="translate(-8084 2467)" fill="url(#pattern)" />
            </g>
        </svg>
    );
};
AppIcon.formSchema = {
    type: "object",
    title: "App Icon",
    properties: {
        image: {
            type: "string",
            format: "data-url",
            title: "Logo/Icon"
        },
        bgcolor: {
            type: "string",
            title: "Background color",
            default: "#000000"
        },
        bradius: {
            title: "Corner radius",
            type: "integer",
            default: 128,
            minimum: 0,
            maximum: 256,
            multipleOf: 16
        },
    }
};
AppIcon.uiSchema = {
    bradius: {
        "ui:widget": "range"
    },
    image: {
        "ui:options": {
            "accept": ".png"
        }
    }
};
export default AppIcon;