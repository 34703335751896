import * as React from "react";

const SplashLogo = ({ image, splashbgcolor }) => {
    return (
        <svg id="diagram" width="300" height="300" viewBox="0 0 300 300" className="shadow">
            <defs>
                <pattern id="pattern" preserveAspectRatio="xMidYMid meet" width="100%" height="100%" viewBox="0 0 1500 1500">
                    <image width="1500" height="1500" href={image || "https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/08/4e401062-logo.png"} />
                </pattern>
            </defs>
            <g id="splash-screen-logo" transform="translate(7470 -2421)">
                <rect id="splash-screen-bg" width="300" height="300" transform="translate(-7470 2421)" fill={splashbgcolor} />
                <rect id="logo_container" width="224" height="224" transform="translate(-7432 2459)" fill="url(#pattern)" />
            </g>
        </svg>
    );
};
SplashLogo.formSchema = {
    type: "object",
    title: "Splash screen logo",
    properties: {
        image: {
            type: "string",
            format: "data-url",
            title: "Logo/Icon"
        },
        splashbgcolor: {
            type: "string",
            title: "Background color",
            default: "#FFFFFF"
        }
    }
};
SplashLogo.uiSchema = {
    image: {
        "ui:options": {
            "accept": ".png"
        }
    }
};
export default SplashLogo;