// @flow strict

import * as React from "react";
import { saveSvgAsPng } from "save-svg-as-png";
import Form from "react-jsonschema-form";
import Banners from "./banners/index";
import BannerChooser from "./BannerChooser";
import { logEvent } from '../../../lib/analytics';


class Banner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			text: "Sample",
			bannerId: "",
			formData: {

			},
			// form: SampleBanner.formSchema
		};
	}
	componentDidMount() {
		logEvent('item_viewed', {
			type: 'banner',
			item_id: this.props.match.params.bannerId
		})
	}
	formSubmit = () => {
		logEvent('item_saved', {
			type: 'banner',
			item_id: this.props.match.params.bannerId
		});
		saveSvgAsPng(document.getElementById("diagram"), "banner.png", {
			scale: 1
		});
	};
	onChange = form => {
		this.setState({
			formData: form.formData
		});
	};

	render() {
		const CurrentBanner = Banners[this.props.match.params.bannerId];
		console.log(CurrentBanner);
		return (
			<React.Fragment>
				<BannerChooser />
				<div className="row p-3">
					<div className="col-md-6 col-12">
						<div className="card">
							<div className="card-header">
								<h5 className="mb-0">Edit banner details</h5>
							</div>
							<div className="card-body">
								<Form
									schema={CurrentBanner.formSchema}
									onSubmit={this.formSubmit}
									onChange={this.onChange}
									formData={this.state.formData}
								>
									<button className="btn btn-info" type="submit">Download Image</button>
								</Form>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-12">
						<div className="card text-center">
							<div className="card-header bg-dark text-white">
								<h5 className="mb-0">Preview</h5>
							</div>
							<div className="card-body">
								<CurrentBanner id="pattern" {...this.state.formData} />
							</div>
							<div class="card-footer">
								<small class="text-muted">Edit details and click "Download Image" to download as image</small>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Banner;
