import * as React from "react";

const GridThree = ({ icon, title3, bgcolor1 }) => {
    return (
        <svg id="diagram" width="150" height="175" viewBox="0 0 300 350">
            <defs>
                <pattern id="pattern" preserveAspectRatio="xMidYMid slice" width="100%" height="100%" viewBox="0 0 512 512">
                    <image width="512" height="512" href={icon || 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/4dffb45f-camera.png'} />
                </pattern>
            </defs>
            <g id="grid-3" transform="translate(-7546 -2836)">
                <rect id="Rectangle_32" data-name="Rectangle 32" width="300" height="350" transform="translate(7546 2836)" fill={bgcolor1} />
                <g id="Group_211" data-name="Group 211" transform="translate(7417.33 2662.49)">
                    <path id="Path_3931" data-name="Path 3931" d="M365.83,475.5a127.281,127.281,0,0,1-9.14,47.5H128.67V410.43A127.509,127.509,0,0,1,365.83,475.5Z" fill="#fff" opacity="0.1" />
                    <path id="Path_3932" data-name="Path 3932" d="M428.67,207.51V446.43a127.554,127.554,0,0,1,0-238.92Z" fill="#fff" opacity="0.1" />
                </g>
                <rect id="camera" width="174" height="174" transform="translate(7609 2900)" fill="url(#pattern)" />
                <text id="CAMERA-2" data-name="CAMERA" transform="translate(7574 3117)" fill="#fff" font-size="30" font-family="Poppins-SemiBold, Poppins" font-weight="600" text-anchor="middle"><tspan x="125" y="32">{title3}</tspan></text>
            </g>
        </svg>
    );
};
GridThree.formSchema = {
    type: "object",

    properties: {
        icon: {
            type: "string",
            format: "data-url",
            title: "Icon"
        },
        title3: {
            type: "string",
            title: "Main title",
            default: "CAMERA"
        },
        bgcolor1: {
            type: "string",
            title: "Background color",
            default: "#008080"
        },
    }
};
GridThree.uiSchema = {
    "bgcolor1": {
        "ui:widget": "color"
    }
};
export default GridThree;