// @flow strict

import React, { useState, useEffect } from "react";
import "./style.css";
import { api } from "../../../lib/api";
import CardList from "./components/CardList";
import options from "./utils/options";
import { pluginListListenerForProject } from "../../../lib/pluginListHelper";
const PluginSupport = props => {
  const { projectId } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [projectPluginList, setProjectPluginList] = useState([]);
  const [error, setError] = useState(false);

  const getPluginList = async status => {
    const resp = projectPluginList.filter(
      item => item.appmakerPluginStatus === status
    );
    return resp;
  };
  const pluginListListener = status =>
    pluginListListenerForProject(status, projectId);
  useEffect(() => {
    api
      .getSupportedPluginListByProjectId(props.match.params.projectId)
      .then(response => {
        if (response.status === "success")
          setProjectPluginList(response.response);
        else setError(true);
        setLoading(false);
      });
  }, []);
  return (
    <div class="ui">
      <nav class="navbar app">Appmaker Plugin process</nav>
      <nav class="navbar board">Total Plugins : {projectPluginList.length}</nav>
      <div class="lists">
        {!loading &&
          !error &&
          [
            { value: "to-process", label: "Not in the list" },
            ...Object.values(options)
          ].map(item => (
            <CardList
              key={`card-item-${item.value}`}
              title={item.label}
              status={item.value}
              pluginListListener={pluginListListener}
            />
          ))}
        {loading && "Loading..."}
        {error && "Error in loading data"}
      </div>
    </div>
  );
};

export default PluginSupport;
