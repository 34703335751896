import * as React from "react";

const LoginPageLogo = ({ image, loginbgcolor }) => {
    return (
        <svg id="diagram" width="450" height="150" viewBox="0 0 450 150" className="shadow">
            <defs>
                <pattern id="pattern" preserveAspectRatio="xMidYMid meet" width="100%" height="100%" viewBox="0 0 450 150">
                    <image preserveAspectRatio="xMidYMid meet" width="100%" height="150" href={image || "https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/08/4e401062-logo.png"} />
                </pattern>
            </defs>
            <g id="login-page-logo" transform="translate(7455 -2895)">
                <rect id="login-page-logo-bg" width="450" height="150" transform="translate(-7455 2895)" fill={loginbgcolor} />
                <rect id="logo_container" width="450" height="150" transform="translate(-7455 2895)" fill="url(#pattern)" />
            </g>
        </svg>
    );
};
LoginPageLogo.formSchema = {
    type: "object",
    title: "Login page logo",
    properties: {
        image: {
            type: "string",
            format: "data-url",
            title: "Logo/Icon"
        },
        loginbgcolor: {
            type: "string",
            title: "Background color",
            default: "#FFFFFF"
        }
    }
};
LoginPageLogo.uiSchema = {
    image: {
        "ui:options": {
            "accept": ".png"
        }
    }
};
export default LoginPageLogo;