import AppIcon from "./AppIcon";
import DrawerHeader from "./DrawerHeader";
import SplashLogo from "./SplashLogo";
import LoginPageLogo from "./LoginPageLogo";
import PushIcon from "./PushIcon";

const AppmakerAssets = {
    'app-icon': AppIcon,
    'drawer-header': DrawerHeader,
    'spalsh-logo': SplashLogo,
    'login-logo': LoginPageLogo,
    'push-icon': PushIcon
}

export default AppmakerAssets;