import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

const availableTemplates = [
    {
        id: 4004,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/11/447d0aa7-high-resolution-logo.png'
    },
    {
        id: 4001,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/ffb14dde-iphonex.png'
    },
    {
        id: 4005,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/11/fdec9123-appstore-screenshot-logo.png'
    },
    {
        id: 4006,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/11/e88db76e-iphonex-plane.png'
    },
    {
        id: 4002,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/19f3eeb7-iphone6s.png'
    },
    {
        id: 4007,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/11/db99b99f-iphone6-logo.png'
    },
    {
        id: 4008,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/11/2baacb46-iphone6-plane.png'
    },
    {
        id: 4003,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/5a0e614c-ipad-nodevice-1.png'
    },
    {
        id: 4009,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/11/0ffc1685-ipad-logo-noframe.png'
    },
    {
        id: 4010,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/11/a5eeb7a9-ipad-air-plane.png'
    },
    {
        id: 4011,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/11/d1cefdb7-ipad-pro-plane.png'
    }
]

const mandatoryId = [4004, 4006, 4008, 4010, 4011];
class AppStoreScreenChooser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false,
            AppStoreScreenTemplates: []
        };
    }
    toggleChange = () => {
        if (!this.state.isChecked) {
            const ImagesArray = [];
            mandatoryId.forEach(id => {
                availableTemplates.forEach(element => {
                    if (element.id === id) {
                        ImagesArray.push(element);
                    }
                })
            })
            this.setState({ AppStoreScreenTemplates: ImagesArray, isChecked: !this.state.isChecked })

        }
        else {
            this.setState({ AppStoreScreenTemplates: availableTemplates, isChecked: !this.state.isChecked })
        }
    }
    componentDidMount() {
        this.setState({ AppStoreScreenTemplates: availableTemplates })
    }

    render() {
        const generatedTemplate = this.state.AppStoreScreenTemplates.map((item) => {
            return (
                <NavLink to={`/design/appstore/${item.id}`} activeClassName="selected-template-style">
                    <img src={item.url}
                        alt="grid template"
                        height="100px"
                        className="ml-3"
                    />
                </NavLink>
            );
        })
        return (
            <div className="bg-dark pt-3">
                <h5 className="text-white ml-3">Select template</h5>
                <div className="custom-control custom-switch my-2 ml-3 text-white">
                    <input type="checkbox" className="custom-control-input" id="mandatorySwitch" checked={this.state.isChecked} onChange={this.toggleChange} />
                    <label className="custom-control-label" for="mandatorySwitch">Show mandatory only</label>
                </div>
                <div className="template-wrapper">
                    {generatedTemplate}
                </div>
            </div>
        );
    }
}

export default AppStoreScreenChooser;