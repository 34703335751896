import GridOne from "./GridOne";
import GridTwo from "./GridTwo";
import GridThree from "./GridThree";

const GridImages = {
    2001: GridOne,
    2002: GridTwo,
    2003: GridThree
}

export default GridImages;