import * as React from "react";

const SampleBanner = ({ image, title1, title2, CTAText }) => {
  return (
    <svg id="diagram" width="360" height="203" viewBox="0 0 1080 608">
      <defs>
        <pattern
          id="pattern"
          preserveAspectRatio="xMidYMid slice"
          width="100%"
          height="100%"
          viewBox="0 0 1266 844"
        >
          <image preserveAspectRatio="xMidYMid meet" width="1266" height="844" clip-path="url(#pattern)" href={image || 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/bcc6ecfc-0c76132e-group-211-1024x576.png'} />
        </pattern>
      </defs>
      <g id="new-banners-app-3" transform="translate(-4496 2742)">
        <g
          id="Group_211"
          data-name="Group 211"
          transform="translate(562 -3018)"
        >
          <rect
            id="img"
            width="1080"
            height="608"
            transform="translate(3934 276)"
            fill="url(#pattern)"
          />
        </g>
        <g
          id="Group_248"
          data-name="Group 248"
          transform="translate(-1304 -13)"
        >
          <rect
            id="Rectangle_179"
            data-name="Rectangle 179"
            width="860"
            height="301"
            transform="translate(5800 -2475)"
            fill="#fff"
            opacity="0.65"
          />
          <text
            id="Women_s_Ready_To_Wear"
            data-name="Women's Ready To Wear"
            transform="translate(5868 -2323)"
            font-size="60"
            font-family="Poppins-Regular, Poppins"
            letter-spacing="0em"
          >
            <tspan x="0" y="0">
              {title1}
            </tspan>
          </text>
          <text
            id="Flaunt_Your_Hot_Body_In_Style_"
            data-name="Flaunt Your Hot Body In Style "
            transform="translate(5868 -2404)"
            fill="#171717"
            font-size="36"
            font-family="Poppins-Regular, Poppins"
            letter-spacing="0em"
          >
            <tspan x="0" y="0">
              {title2}
            </tspan>
          </text>
          <rect
            id="Rectangle_159"
            data-name="Rectangle 159"
            width="323"
            height="75"
            transform="translate(5868 -2284)"
          />
          <text
            id="SHOP_NOW"
            data-name="SHOP NOW"
            transform="translate(5917 -2232)"
            fill="#fff"
            font-size="42"
            font-family="Poppins-Medium, Poppins"
            font-weight="500"
            letter-spacing="0.001em"
            text-anchor="middle"
          >
            <tspan x="110" y="0">
              {CTAText}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};
SampleBanner.formSchema = {
  type: "object",

  properties: {
    image: {
      type: "string",
      format: "data-url",
      title: "Background image"
    },
    title1: {
      type: "string",
      title: "Main title",
      default: "Catch the big deal"
    },
    title2: {
      type: "string",
      title: "Sub title",
      default: "Special offer"
    },
    CTAText: {
      type: "string",
      title: "Button text",
      default: "SHOP NOW"
    }
  }
};
export default SampleBanner;