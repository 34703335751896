import * as React from "react";

const DrawerHeader = ({ image, bgcolor, opacity }) => {
    return (
        <svg id="diagram" width="300" height="150" viewBox="0 0 600 300">
            <defs>
                <pattern id="pattern" preserveAspectRatio="xMidYMid meet" width="100%" height="100%" viewBox="0 0 225 225">
                    <image preserveAspectRatio="xMidYMid meet" width="225" height="225" href={image || "https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/08/4e401062-logo.png"} />
                </pattern>
            </defs>
            <g id="drawer-header" transform="translate(7470 -3088)">
                <rect id="drawer-header-bg" width="600" height="300" transform="translate(-7470 3088)" fill={bgcolor} />
                <g id="logo-pattern-group" transform="translate(8 8)">
                    <rect id="logo_container" width="39" height="39" transform="translate(-7463 3096)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-2" data-name="logo_container" width="39" height="39" transform="translate(-7408 3096)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-3" data-name="logo_container" width="39" height="39" transform="translate(-7357 3096)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-4" data-name="logo_container" width="39" height="39" transform="translate(-7304 3096)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-5" data-name="logo_container" width="39" height="39" transform="translate(-7250 3096)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-6" data-name="logo_container" width="39" height="39" transform="translate(-7197 3096)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-7" data-name="logo_container" width="39" height="39" transform="translate(-7144 3096)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-8" data-name="logo_container" width="39" height="39" transform="translate(-7091 3096)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-9" data-name="logo_container" width="39" height="39" transform="translate(-7038 3096)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-10" data-name="logo_container" width="39" height="39" transform="translate(-6986 3096)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-11" data-name="logo_container" width="39" height="39" transform="translate(-6931 3096)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-12" data-name="logo_container" width="39" height="39" transform="translate(-7463 3153)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-13" data-name="logo_container" width="39" height="39" transform="translate(-7408 3153)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-14" data-name="logo_container" width="39" height="39" transform="translate(-7357 3153)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-15" data-name="logo_container" width="39" height="39" transform="translate(-7304 3153)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-16" data-name="logo_container" width="39" height="39" transform="translate(-7250 3153)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-17" data-name="logo_container" width="39" height="39" transform="translate(-7197 3153)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-18" data-name="logo_container" width="39" height="39" transform="translate(-7144 3153)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-19" data-name="logo_container" width="39" height="39" transform="translate(-7091 3153)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-20" data-name="logo_container" width="39" height="39" transform="translate(-7038 3153)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-21" data-name="logo_container" width="39" height="39" transform="translate(-6986 3153)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-22" data-name="logo_container" width="39" height="39" transform="translate(-6931 3153)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-23" data-name="logo_container" width="39" height="39" transform="translate(-7463 3211)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-24" data-name="logo_container" width="39" height="39" transform="translate(-7408 3211)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-25" data-name="logo_container" width="39" height="39" transform="translate(-7357 3211)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-26" data-name="logo_container" width="39" height="39" transform="translate(-7304 3211)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-27" data-name="logo_container" width="39" height="39" transform="translate(-7250 3211)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-28" data-name="logo_container" width="39" height="39" transform="translate(-7197 3211)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-29" data-name="logo_container" width="39" height="39" transform="translate(-7144 3211)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-30" data-name="logo_container" width="39" height="39" transform="translate(-7091 3211)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-31" data-name="logo_container" width="39" height="39" transform="translate(-7038 3211)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-32" data-name="logo_container" width="39" height="39" transform="translate(-6986 3211)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-33" data-name="logo_container" width="39" height="39" transform="translate(-6931 3211)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-34" data-name="logo_container" width="39" height="39" transform="translate(-7463 3268)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-35" data-name="logo_container" width="39" height="39" transform="translate(-7408 3268)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-36" data-name="logo_container" width="39" height="39" transform="translate(-7357 3268)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-37" data-name="logo_container" width="39" height="39" transform="translate(-7304 3268)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-38" data-name="logo_container" width="39" height="39" transform="translate(-7250 3268)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-39" data-name="logo_container" width="39" height="39" transform="translate(-7197 3268)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-40" data-name="logo_container" width="39" height="39" transform="translate(-7144 3268)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-41" data-name="logo_container" width="39" height="39" transform="translate(-7091 3268)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-42" data-name="logo_container" width="39" height="39" transform="translate(-7038 3268)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-43" data-name="logo_container" width="39" height="39" transform="translate(-6986 3268)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-44" data-name="logo_container" width="39" height="39" transform="translate(-6931 3268)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-45" data-name="logo_container" width="39" height="39" transform="translate(-7463 3325)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-46" data-name="logo_container" width="39" height="39" transform="translate(-7408 3325)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-47" data-name="logo_container" width="39" height="39" transform="translate(-7357 3325)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-48" data-name="logo_container" width="39" height="39" transform="translate(-7304 3325)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-49" data-name="logo_container" width="39" height="39" transform="translate(-7250 3325)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-50" data-name="logo_container" width="39" height="39" transform="translate(-7197 3325)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-51" data-name="logo_container" width="39" height="39" transform="translate(-7144 3325)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-52" data-name="logo_container" width="39" height="39" transform="translate(-7091 3325)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-53" data-name="logo_container" width="39" height="39" transform="translate(-7038 3325)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-54" data-name="logo_container" width="39" height="39" transform="translate(-6986 3325)" opacity="0.1" fill="url(#pattern)" />
                    <rect id="logo_container-55" data-name="logo_container" width="39" height="39" transform="translate(-6931 3325)" opacity="0.1" fill="url(#pattern)" />
                </g>
            </g>
        </svg>
    );
};
DrawerHeader.formSchema = {
    type: "object",
    title: "Drawer header image",
    properties: {
        image: {
            type: "string",
            format: "data-url",
            title: "Logo/Icon"
        },
        bgcolor: {
            type: "string",
            title: "Background color",
            default: "#141414"
        }
    }
};
DrawerHeader.uiSchema = {
    image: {
        "ui:options": {
            "accept": ".png"
        }
    }
};
export default DrawerHeader;