import React, { useState, useEffect } from "react";
// import { pluginListListener } from "../../../../lib/pluginListHelper";
// import options from "../utils/options";

const CardList = ({ title, status, pluginListListener, getPluginList }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [pluginList, setPluginList] = useState([]);
  useEffect(() => {
    pluginListListener &&
      pluginListListener(status).onSnapshot(querySnapshot => {
        var docs = [];
        querySnapshot.forEach(doc => {
          console.log(doc.data());
          docs.push(doc.data());
        });
        setPluginList(docs);
        setLoaded(true);
      });
    getPluginList &&
      getPluginList(status).then(resp => {
        setPluginList(resp);
        setLoaded(true);
      });
  }, []);
  return (
    <div class="list">
      <header>
        {title} - {pluginList.length}
      </header>
      <ul>
        {isLoaded &&
          pluginList.map((item, key) => (
            <li>
              {item["Title"]}{" "}
              <span class="badge badge-success">{item.installs}</span>
            </li>
          ))}
      </ul>
      {/* <footer>Add a card...</footer> */}
    </div>
  );
};

export default CardList;
