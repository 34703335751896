import CovidBannerOne from './CovidBannerOne';
import CovidBannerTwo from './CovidBannerTwo';
import CovidBannerThree from './CovidBannerThree';

const Banners = {
    5001: CovidBannerOne,
    5002: CovidBannerTwo,
    5003: CovidBannerThree
}

export default Banners;