import * as React from "react";

const GridTwo = ({ icon, title2, bgcolor, ellipsebg }) => {
    return (
        <svg id="diagram" width="150" height="175" viewBox="0 0 300 350">
            <defs>
                <pattern id="pattern" preserveAspectRatio="xMidYMid slice" width="100%" height="100%" viewBox="0 0 512 512">
                    <image width="512" height="512" href={icon || 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/8436d82c-grocery.png'} />
                </pattern>
            </defs>
            <g id="grid-2" transform="translate(-7115 -2836)">
                <rect id="Rectangle_31" data-name="Rectangle 31" width="300" height="350" transform="translate(7115 2836)" fill={bgcolor} />
                <circle id="Ellipse_40" data-name="Ellipse 40" cx="105" cy="105" r="105" transform="translate(7160 2870)" fill={ellipsebg} />
                <rect id="grocery" width="108" height="108" transform="translate(7211 2921)" fill="url(#pattern)" />
                <text id="GROCERY-2" data-name="GROCERY" transform="translate(7143 3117)" font-size="30" font-family="Poppins-SemiBold, Poppins" font-weight="600" text-anchor="middle"><tspan x="125" y="32">{title2}</tspan></text>
            </g>
        </svg>
    );
};
GridTwo.formSchema = {
    type: "object",

    properties: {
        icon: {
            type: "string",
            format: "data-url",
            title: "Icon"
        },
        title2: {
            type: "string",
            title: "Main title",
            default: "GROCERY"
        },
        bgcolor: {
            type: "string",
            title: "Background color",
            default: "#D5D6D9"
        },
        ellipsebg: {
            type: "string",
            title: "Circle background color",
            default: "white"
        }
    }
};
export default GridTwo;