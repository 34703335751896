// @flow strict

import React, { useState } from "react";
// import { JsonToTable } from "react-json-to-table";
import { db } from "../../../../lib/firebase";
import * as pluginHelper from "../../../../lib/pluginListHelper";
import options from "../utils/options";
window.db = db;
window.pluginHelper = pluginHelper;

const PluginItem = ({ data }) => {
  const [pluginSupported, setPluginSupported] = useState(
    data.appmakerPluginStatus
  );
  const updatePluginSupported = value => {
    console.log("Updating ", value);
    // pluginHelper()
    pluginHelper.createOrUpdatePluginSupport({
      pluginData: data,
      status: value
    });
    setPluginSupported(value);
  };

  return (
    <div className="card border-success">
      <div className="card-body">
        <h5 className="card-title">
          {data["Title"]}{" "}
          <span class="badge badge-success">{data.installs}</span>
        </h5>
        <p className="card-text">{data.Description}</p>
      </div>
      <ul className="list-group list-group-flush">
        {data.PluginURI && (
          <li className="list-group-item">
            <a target="_blank" href={data.PluginURI} rel="noopener noreferrer">
              Open PluginURI
            </a>
          </li>
        )}
        {data.AuthorURI && (
          <li className="list-group-item">
            <a target="_blank" href={data.AuthorURI} rel="noopener noreferrer">
              Open AuthorURI
            </a>
          </li>
        )}
         <li className="list-group-item text-center">
          <button
            onClick={() => {
              pluginHelper.addToPluginList(data).then(console.log);
            }}
          >
            Add to list
          </button>
        </li>
        <li className="list-group-item text-center">
          Plugin supported : {pluginSupported}
        </li>
        {Object.values(options).map(item => {
          return (
            <li className="list-group-item text-center">
              <button
                onClick={() => updatePluginSupported(item.value)}
                type="button"
                class="btn btn-primary btn-block"
              >
                {item.label}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PluginItem;
