import SampleBanner from './BasicBanner';
import BannerTwo from './BannerTwo';
import BannerThree from './BannerThree';

const Banners = {
    1001: SampleBanner,
    1002: BannerTwo,
    1003: BannerThree
}

export default Banners;