// @flow strict

import * as React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ProcessList from "./ProcessList";
import { NavMenu } from "../index";
import ViewList from "./ViewList";
import ViewPluginsProject from "./ViewPluginsProject";
import withAuth from "../../hoc/withAuth";
import * as routes from '../../../constants/routes';

function PluginSupportRoot() {
  return (
    <Router>
      <Route path={routes.PLUGIN_SUPPORT} component={NavMenu} />
      <Route
        path={routes.PROCESS_LIST}
        component={ProcessList}
      />
      <Route path={routes.VIEW_LIST} component={ViewList} />
      <Route
        path={routes.VIEW_PLUGIN_PROJECT}
        component={ViewPluginsProject}
      />
    </Router>
  );
}

export default withAuth(PluginSupportRoot);
