import * as React from "react";

const BannerTwo = ({ image, title1, CTAText, pricolor, btntext }) => {
    return (
        <svg id="diagram" width="360" height="200" viewBox="0 0 1080 608">
            <defs>
                <pattern id="pattern" preserveAspectRatio="xMidYMid slice" width="100%" height="100%" viewBox="0 0 600 415">
                    <image preserveAspectRatio="xMidYMid slice" width="600" height="415" href={image || 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/e8b365df-9e2ac2e7-vadim-sherbakov-tciclj5ktbe-unsplash-1024x674.jpg'} />
                </pattern>
                <filter id="button-outline" x="348" y="441" width="384" height="97" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="blur-2" />
                    <feFlood flood-opacity="0.529" />
                    <feComposite operator="in" in2="blur-2" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="BANNER-2" transform="translate(-3549 -2836)">
                <rect id="image" width="1080" height="600" transform="translate(3549 2836)" fill="url(#pattern)" />
                <g transform="matrix(1, 0, 0, 1, 3549, 2836)">
                    <text id="This_is_a_title-2" data-name="This is a title" transform="translate(540 380)" fill={pricolor} font-size="60" font-family="Poppins-Bold, Poppins" text-anchor="middle"><tspan x="0" y="0">{title1}</tspan></text>
                </g>
                <g id="button">
                    <g transform="matrix(1, 0, 0, 1, 3549, 2836)" filter="url(#button-outline)">
                        <rect id="button-outline-2" data-name="button-outline" width="366" height="79" rx="40" transform="translate(357 447)" fill={pricolor} />
                    </g>
                    <text id="SHOP_NOW" data-name="SHOP NOW" transform="translate(4089 3336)" font-size="38" fill={btntext} font-family="Poppins-SemiBold, Poppins" text-anchor="middle"><tspan x="0" y="0">{CTAText}</tspan></text>
                </g>
            </g>
        </svg >
    );
};
BannerTwo.formSchema = {
    type: "object",

    properties: {
        image: {
            type: "string",
            format: "data-url",
            title: "Background image"
        },
        title1: {
            type: "string",
            title: "Main title",
            default: "Catch the big deal"
        },
        CTAText: {
            type: "string",
            title: "Button text",
            default: "SHOP NOW"
        },
        pricolor: {
            type: "string",
            default: "#ffffff",
            title: "Primary color  (Color name/Hex code)"
        },
        btntext: {
            type: "string",
            title: "Button text color",
            default: "black"
        }
    }
};
export default BannerTwo;