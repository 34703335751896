import React, { Component } from 'react';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Banner from './bannerMaker/Banner';
import GridImage from './gridImageMaker/GridImage';
import PlaystoreScreenshot from './screenshotMaker/playstoreScreenshot/PlaystoreScreenshot';
import AppStoreScreenshot from './screenshotMaker/appstoreScreenshot/AppStoreScreenshot';
import AppmakerAsset from './appmakerAssets/AppmakerAsset';
import CovidBanner from './covidBanner/CovidBanner';

function AppmakerLogo() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 30 30" className="mr-2 mb-1">
            <g transform="translate(5432 -3154)">
                <rect width="8.355" height="14.094" transform="translate(-5421.165 3160.383)" fill="#fff" />
                <path d="M25.363,0H4.637A4.637,4.637,0,0,0,0,4.637V25.363A4.637,4.637,0,0,0,4.637,30H25.363A4.637,4.637,0,0,0,30,25.363V4.637A4.637,4.637,0,0,0,25.363,0ZM21.471,23.527A2.675,2.675,0,0,1,18.8,26.2H15.889A1.878,1.878,0,1,0,14,26.2H11.2a2.675,2.675,0,0,1-2.675-2.675V6.473A2.675,2.675,0,0,1,11.2,3.8h7.6a2.675,2.675,0,0,1,2.675,2.675Z" transform="translate(-5432 3154)" fill="#fff" />
            </g>
        </svg>
    );
}
class DesignTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <React.Fragment>
                <Router>
                    <div className="row productivity tools">
                        <div className="side-nav col-md-2 col-12 darkmode-bg px-0">
                            <a href="/" className="text-decoration-none"><h6 className="text-white font-weight-bold bg-danger p-3 text-center text-uppercase overflow-hidden">design tools</h6></a>
                            <NavLink to="/design/app-assets/app-icon" className="btn btn-block text-white rounded-0 text-left " activeClassName="btn-info"><AppmakerLogo />Appmaker assets</NavLink>
                            <NavLink to="/design/banner/1001" className="btn btn-block text-white rounded-0 text-left " activeClassName="btn-info"><i className="fas fa-image mr-2"></i>Banner maker</NavLink>
                            <NavLink to="/design/grid/2001" className="btn btn-block text-white rounded-0 text-left " activeClassName="btn-info"><i className="fas fa-images mr-2"></i>Grid image maker</NavLink>
                            <NavLink to="/design/playscreen/3005" className="btn btn-block text-white rounded-0 text-left " activeClassName="btn-info"><i className="fab fa-google-play mr-2"></i>Playstore assets</NavLink>
                            <NavLink to="/design/appstore/4004" className="btn btn-block text-white rounded-0 text-left " activeClassName="btn-info"><i className="fab fa-app-store-ios mr-2"></i>Appstore assets</NavLink>
                            <NavLink to="/design/covidNotice/5001" className="btn btn-block text-white rounded-0 text-left " activeClassName="btn-info"><i className="fas fa-shield-virus mr-2"></i><b>COVID NOTICE</b></NavLink>
                        </div>
                        <div className="tools-main-view col-md-10 col-12 px-0">
                            <Route path={'/design/app-assets/:assetId'} component={AppmakerAsset} />
                            <Route path={'/design/banner/:bannerId'} component={Banner} />
                            <Route path={'/design/grid/:gridId'} component={GridImage} />
                            <Route path={'/design/playscreen/:playScreenId'} component={PlaystoreScreenshot} />
                            <Route path={'/design/appstore/:appstoreScreenId'} component={AppStoreScreenshot} />
                            <Route path={'/design/covidNotice/:bannerId'} component={CovidBanner} />
                        </div>
                    </div>
                </Router>
            </React.Fragment>
        );
    }
}

export default DesignTools;