import * as React from "react";

const CovidBannerOne = ({ title1, date }) => {
  return (
    <svg id="diagram" width="360" height="203" viewBox="0 0 1080 536">
      <g id="CovidBanner1" transform="translate(-10301 -251)">
        <rect id="Rectangle_607" data-name="Rectangle 607" width="1080" height="536.329" transform="translate(10301 251)" fill="#f4eeff" />
        <text id="banner_content" transform="translate(10386.999 545.706)" fill="#1c1c1c" font-size="26.48" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Due to the recent lockdown imposed</tspan><tspan x="0" y="36">in the wake of COVID-19, all orders will be </tspan><tspan x="0" y="72">accepted only post {date}</tspan></text>
        <text id="Main_title" data-name="We will be back soon." transform="translate(10386.999 458.2)" fill="#0f4283" font-size="46.48" font-family="Poppins-Bold, Poppins" font-weight="700"><tspan x="0" y="0">{title1}</tspan></text>
        <g id="Group_261" data-name="Group 261">
          <g id="Group_242" data-name="Group 242" transform="translate(9596 326.432)" opacity="0.14">
            <path id="Path_3968" data-name="Path 3968" d="M1646.843,159.505c35.44,3.743,81.874,9.774,98.858,34.312,16.885,24.538,4.32,67.687-13.253,101.167s-40.348,57.29-61.16,82.66c-20.911,25.265-40.054,52.091-64.4,59.369s-53.994-4.991-90.121-9.15c-36.225-4.055-79.027,0-110.245-17.883-31.12-17.884-50.755-57.706-56.448-99.608s2.552-85.883,21.99-121.65c19.536-35.871,50.361-63.321,84.034-68,33.672-4.782,70.094,13.413,100.526,23.291S1611.4,155.658,1646.843,159.505Z" fill="#a7a7a7" />
          </g>
          <g id="Group_260" data-name="Group 260" transform="translate(9596 326.432)">
            <g id="Group_243" data-name="Group 243">
              <path id="Path_3969" data-name="Path 3969" d="M1527.953,367.69l7.067,8.342,10.586,9.315c1.09.773,1.394,5.753-.541,5.477l-10.917.821c-3.48.262-7.212-1.043-10.351-2.6-2.288-1.133-4.52-3.035-7.375-4.8l-1.241,2.275-10.292-3.54-.248-6.951c-1.3-7.482,1.691-8.7,2.728-13.9l17.98.381Z" fill="#1f1c1d" fill-rule="evenodd" />
              <path id="Path_3970" data-name="Path 3970" d="M1480.347,362.991l-.418,19.593-10.3,10.87-16.853-2.148-.7-14.664,9.052-16.051Z" fill="#1f1c1d" fill-rule="evenodd" />
              <path id="Path_3971" data-name="Path 3971" d="M1472.663,159.382l-6.853,32.941a154.2,154.2,0,0,0-3.685,29.41l-5.442,142.922,12.6,3.288,14.336-1.518,14.645-149.411,3.2-1.51,4.869-55.87Z" fill="#0f4283" fill-rule="evenodd" />
              <path id="Path_3972" data-name="Path 3972" d="M1535.139,159.382l4.546,25.926c3.109,17.734,2.807,24.335,1.416,42.246l-10.834,139.628-13.08,1.116-14.5-.61.775-149.663-2.027-2.521v-55.87Z" fill="#072c5e" fill-rule="evenodd" />
              <path id="Path_3973" data-name="Path 3973" d="M1524,215.5l-6.684,152.69-14.623-.506.775-149.663-2.027-2.521v-55.87l14.774,1.383Z" fill="#0f4283" fill-rule="evenodd" />
            </g>
            <g id="Group_245" data-name="Group 245">
              <g id="Group_244" data-name="Group 244">
                <path id="Path_3974" data-name="Path 3974" d="M1486.9,32.714h24.4c-.061,6.6-.132,13.123,0,19.719l-7.854,17.7L1486.27,52.558Z" fill="#f0c17d" fill-rule="evenodd" />
                <path id="Path_3975" data-name="Path 3975" d="M1498.869,42.093a1.63,1.63,0,0,1-.2.008c-2.445.015-3.661-.025-4.99-.586-1.284-.547-1.907-1.807-3.675-3.509-1.5-1.446-4.2-2.922-4.855-4.5-.8-1.919-.611-4.009-.95-6.8-1.4.768-2.889-3.225-3.184-4.533-.436-1.932-1.827-5.216-2.262-7.42-.25-1.263-.1-2.265.327-2.583.905-.673,1.836-.306,3.184,1.529.087-1.555.188-3.16.136-4.864a42.292,42.292,0,0,1,.207-8.071,9.493,9.493,0,0,1,3.122-5.787h0v0a16.089,16.089,0,0,1,3.434-2.31c.013,0,.026-.01.041-.016.08-.037.166-.078.246-.121.05-.022.094-.043.142-.069.064-.032.132-.063.2-.089s.127-.058.194-.085a1.7,1.7,0,0,1,.211-.091c.061-.032.123-.058.186-.084.09-.037.178-.074.271-.1.041-.017.084-.038.129-.055.13-.052.266-.1.4-.147l0,0a22.415,22.415,0,0,1,10.754-1.021,18.276,18.276,0,0,1,9.482,4.174,9.489,9.489,0,0,1,3.109,5.761,41.887,41.887,0,0,1,.224,8.041c-.05,1.715.056,3.328.147,4.891,1.325-1.787,2.249-2.14,3.145-1.474.425.318.575,1.32.326,2.583-.434,2.2-1.824,5.488-2.261,7.42-.293,1.3-1.754,5.206-3.135,4.557-.307,2.7-.14,4.738-.916,6.626-.656,1.585-3.332,3.08-4.826,4.539-1.758,1.724-2.376,2.986-3.653,3.553A11.087,11.087,0,0,1,1498.869,42.093Z" fill="#ffdba7" fill-rule="evenodd" />
              </g>
              <path id="Path_3976" data-name="Path 3976" d="M1487.013-1.644c-1.17,1.367-4.139,10.762-3.743,19.741-3.962-14.032-6.09-24.051,2.51-30.361,11.29-8.28,24.922-2.96,26.023.22,9.036,3.093,5.412,17.057,2.921,28.173-.079-5.572-1.281-12.495-4.145-17.355-.683-.644-3.613,3.057-4.287,3.014C1499.608,1.374,1497.277-.05,1487.013-1.644Z" fill="#632404" fill-rule="evenodd" />
            </g>
            <path id="Path_3977" data-name="Path 3977" d="M1531.66,59.008c2.07.59,4.81,1.093,6,2.15,5.192,4.618,5.544,14.416,6.952,22.539,2.366,13.673,7.875,28.925,10.149,42.68l-10.364,5.815-7.078,4.55c-3.374-12.793-9.876-24.861-12.543-37.819Z" fill="#5782bc" fill-rule="evenodd" />
            <path id="Path_3978" data-name="Path 3978" d="M1469.421,168.838c.386-3.936,1.09-9.23.885-10.38-3-16.929-.839-24.473-4.149-41.695-2.713-14.123-7.438-31.077-10.833-57.192l20.884-6.313,9.592-6.921q.227,2.166.575,4.344l9.321,9.68,9.075,2.355,2.217-.534,5.871-9.241q-.45-1.265-.94-2.508c8.489,4.85,10.248,6.022,16.317,7.554.814.2,3.856,1.081,5.765,1.719,3.609,1.2,2.864,3.282,4.071,8.306,2.143,8.9.727,20.619-.841,32.687-.991,7.627-2.461,47.917-1.708,55.372.431,4.265,1.111,8.491,1.868,12.767Z" fill="#6492d0" fill-rule="evenodd" />
            <path id="Path_3979" data-name="Path 3979" d="M1523.156,56.41a40.588,40.588,0,0,0,5.08,1.577c.814.2,3.856,1.081,5.765,1.719,3.609,1.2,2.864,3.282,4.071,8.306,2.143,8.9.727,20.619-.841,32.687-.991,7.627-2.461,47.917-1.708,55.372.431,4.265,1.111,8.491,1.868,12.767l-20.061,0a192.2,192.2,0,0,1,.81-37.341C1520.939,109.547,1523.748,82.262,1523.156,56.41Z" fill="#4c77b0" fill-rule="evenodd" />
            <path id="Path_3980" data-name="Path 3980" d="M1498.981-19.329c20.9,1.5,19.413,19.947,18.252,25.081-3.323-6.907-10.956-9.263-18.669-8.808-7.712-.455-15.345,1.9-18.67,8.808-1.159-5.134-2.646-23.577,18.254-25.081l.416-.023Z" fill="#6492d0" fill-rule="evenodd" />
            <path id="Path_3981" data-name="Path 3981" d="M1498.566-4.883c6.648-.071,12.88,2.773,17.792,8.569-1.524,5.125-2.665,4.994-7.451,2.2-2.6-1.519-4.866-4.671-10.341-4.671s-7.8,3.277-10.342,4.671c-4.855,2.667-5.925,2.925-7.45-2.2C1485.686-2.11,1491.917-4.954,1498.566-4.883Z" fill="#76a5e3" fill-rule="evenodd" />
            <path id="Path_3982" data-name="Path 3982" d="M1502.627,62.731a224.64,224.64,0,0,0,2.129,26.574c.489,3.293.583,3.648,4.119,3.674.558,0,1.19.009,1.879.022l.058,1.43c-.7-.013-1.312-.019-1.848-.023-5.085-.039-5.257-.041-5.756-5.064a198.225,198.225,0,0,1-1.852-26.609Z" fill="#5782bc" fill-rule="evenodd" />
            <path id="Path_3983" data-name="Path 3983" d="M1486.3,43.735c4.8,8.045,9.689,12.328,17.77,18.794a19.931,19.931,0,0,0-6.9,9.755c-6.125-8.219-10.09-15.547-11.861-25.47Z" fill="#76a5e3" fill-rule="evenodd" />
            <path id="Path_3984" data-name="Path 3984" d="M1511.207,47.867a33.543,33.543,0,0,1-7.192,14.647,49.732,49.732,0,0,1,5.926,9.7,39.572,39.572,0,0,0,3.937-21.734Z" fill="#76a5e3" fill-rule="evenodd" />
            <g id="Group_246" data-name="Group 246">
              <path id="Path_3985" data-name="Path 3985" d="M1507.544,91.107a1.333,1.333,0,1,0-1.332-1.334A1.335,1.335,0,0,0,1507.544,91.107Z" fill="#8bb7f0" fill-rule="evenodd" />
              <path id="Path_3986" data-name="Path 3986" d="M1506.594,82.335A1.335,1.335,0,1,0,1505.262,81,1.337,1.337,0,0,0,1506.594,82.335Z" fill="#8bb7f0" fill-rule="evenodd" />
              <path id="Path_3987" data-name="Path 3987" d="M1505.774,73.8a1.334,1.334,0,1,0-1.335-1.333A1.335,1.335,0,0,0,1505.774,73.8Z" fill="#8bb7f0" fill-rule="evenodd" />
            </g>
            <path id="Path_3988" data-name="Path 3988" d="M1455.856,59.459c-2.529.228-3.979,6.42-5.552,14.248-1.98,9.852-5.617,31.346-8.041,45.512-1.832,10.7,2.878,17.846,5.992,18.376,3.134.533,11.714-2.964,13.558-9.428,2.247-7.881,3.633-17.163,5.886-26.359C1467.488,89.633,1462.347,63.048,1455.856,59.459Z" fill="#6492d0" fill-rule="evenodd" />
            <path id="Path_3989" data-name="Path 3989" d="M1460.18,58.278c-.327-1.719.013-3.183-.651-6.834-.616-3.4-2.173-6.947-4.963-6.247-2.1.528-1.621,4.043-.929,6.906.986,4.078,1.407,8.476-.08,11.852-.8,1.808-3.686,3.646-2.971,7.575-1.086,1.229-2.051,8.024-1.689,12.359.276,3.3,1.519,6.4,5.222,6.811a8.443,8.443,0,0,0,3.607-.559c4.449-.006,7.412.041,7.866-1.128a6.357,6.357,0,0,0,.1-3.42c-.243-.564-1.672-.532-3.507-.726.7-.049,4.886-.192,5.356-.388,1.73-.719,1.529-3.718.8-4.55s-2.437-.767-6.031-1.036c2.656-.057,5.6.336,6.834-.145,1.163-.455,1.284-5.082.119-5.556-.957-.39-3.549-.4-7.143-1,3.628.06,5.859.618,6.787.366.873-.235,1.254-3.72.855-4.653-1.049-2.446-5.828-1.306-7.5-3.4C1461.316,63.311,1460.66,60.809,1460.18,58.278Z" fill="#ffdba7" fill-rule="evenodd" />
            <g id="Group_259" data-name="Group 259">
              <g id="Group_250" data-name="Group 250">
                <g id="Group_247" data-name="Group 247">
                  <rect id="Rectangle_608" data-name="Rectangle 608" width="134.663" height="114.251" transform="translate(1537.438 279.203)" fill="#d8bd76" />
                  <rect id="Rectangle_609" data-name="Rectangle 609" width="134.887" height="25.195" transform="translate(1537.438 279.201)" fill="#c4aa68" />
                  <rect id="Rectangle_610" data-name="Rectangle 610" width="141.31" height="20.238" transform="translate(1534.115 277.41)" fill="#e7d691" />
                </g>
                <g id="Group_249" data-name="Group 249">
                  <rect id="Rectangle_611" data-name="Rectangle 611" width="45.868" height="20.807" transform="translate(1618.479 306.984)" fill="#dfe9f2" />
                  <g id="Group_248" data-name="Group 248">
                    <rect id="Rectangle_612" data-name="Rectangle 612" width="4.876" height="15.478" transform="translate(1621.831 309.649)" fill="#818286" />
                    <rect id="Rectangle_613" data-name="Rectangle 613" width="4.874" height="15.478" transform="translate(1630.534 309.649)" fill="#818286" />
                    <rect id="Rectangle_614" data-name="Rectangle 614" width="4.874" height="15.478" transform="translate(1639.525 309.649)" fill="#818286" />
                    <rect id="Rectangle_615" data-name="Rectangle 615" width="4.874" height="15.478" transform="translate(1647.764 309.649)" fill="#818286" />
                    <rect id="Rectangle_616" data-name="Rectangle 616" width="4.874" height="15.478" transform="translate(1656.119 309.649)" fill="#818286" />
                  </g>
                </g>
              </g>
              <g id="Group_254" data-name="Group 254">
                <g id="Group_251" data-name="Group 251">
                  <rect id="Rectangle_617" data-name="Rectangle 617" width="134.663" height="114.249" transform="translate(1537.438 163.312)" fill="#d8bd76" />
                  <rect id="Rectangle_618" data-name="Rectangle 618" width="134.887" height="25.195" transform="translate(1537.438 163.31)" fill="#c4aa68" />
                  <rect id="Rectangle_619" data-name="Rectangle 619" width="141.31" height="20.236" transform="translate(1534.115 161.52)" fill="#e7d691" />
                </g>
                <g id="Group_253" data-name="Group 253">
                  <rect id="Rectangle_620" data-name="Rectangle 620" width="45.868" height="20.809" transform="translate(1618.479 191.091)" fill="#dfe9f2" />
                  <g id="Group_252" data-name="Group 252">
                    <rect id="Rectangle_621" data-name="Rectangle 621" width="4.876" height="15.48" transform="translate(1621.831 193.756)" fill="#818286" />
                    <rect id="Rectangle_622" data-name="Rectangle 622" width="4.874" height="15.48" transform="translate(1630.534 193.756)" fill="#818286" />
                    <rect id="Rectangle_623" data-name="Rectangle 623" width="4.874" height="15.48" transform="translate(1639.525 193.756)" fill="#818286" />
                    <rect id="Rectangle_624" data-name="Rectangle 624" width="4.874" height="15.48" transform="translate(1647.764 193.756)" fill="#818286" />
                    <rect id="Rectangle_625" data-name="Rectangle 625" width="4.874" height="15.48" transform="translate(1656.119 193.756)" fill="#818286" />
                  </g>
                </g>
              </g>
              <g id="Group_258" data-name="Group 258">
                <g id="Group_255" data-name="Group 255">
                  <rect id="Rectangle_626" data-name="Rectangle 626" width="134.661" height="114.249" transform="translate(1537.438 47.02)" fill="#d8bd76" />
                  <rect id="Rectangle_627" data-name="Rectangle 627" width="134.887" height="25.195" transform="translate(1537.436 47.018)" fill="#c4aa68" />
                  <rect id="Rectangle_628" data-name="Rectangle 628" width="141.308" height="20.238" transform="translate(1534.115 45.226)" fill="#e7d691" />
                </g>
                <g id="Group_257" data-name="Group 257">
                  <rect id="Rectangle_629" data-name="Rectangle 629" width="45.87" height="20.809" transform="translate(1618.477 74.799)" fill="#dfe9f2" />
                  <g id="Group_256" data-name="Group 256">
                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4.874" height="15.48" transform="translate(1621.831 77.464)" fill="#818286" />
                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4.876" height="15.48" transform="translate(1630.532 77.464)" fill="#818286" />
                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4.876" height="15.48" transform="translate(1639.524 77.464)" fill="#818286" />
                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4.876" height="15.48" transform="translate(1647.762 77.464)" fill="#818286" />
                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4.876" height="15.48" transform="translate(1656.117 77.464)" fill="#818286" />
                  </g>
                </g>
              </g>
            </g>
            <path id="Path_3990" data-name="Path 3990" d="M1550.99,40.925c6.58-2.514,15.634-1.874,21.58-.638-1.872,5.88-1.894,12.128-3.473,11.881l-10.2-.227C1555.178,47.479,1551.565,44.37,1550.99,40.925Z" fill="#ffdba7" fill-rule="evenodd" />
          </g>
        </g>
      </g>
    </svg>
  );
};
CovidBannerOne.formSchema = {
  type: "object",

  properties: {
    title1: {
      type: "string",
      title: "Main title",
      default: "We will be back soon.",
    },
    date: {
      type: "string",
      title: "Date of reopening",
      default: "15th April 2020",
    },
  }
};
export default CovidBannerOne;