import React from "react";
import { useFirebaseAuth } from "use-firebase-auth";
import Login from "../auth/login";
const withAuth = Component => {
  return props => {
    const { user, loading, error } = useFirebaseAuth();
    console.log(JSON.stringify(user));
    return (
      <>
        {user ? (
          <Component {...props} />
        ) : loading ? (
          `Loading...`
        ) : (
          <>
            {error && (
              <div className="mb-4 text-red-600 text-base text-center">
                {error.message}
              </div>
            )}
            <Login />
          </>
        )}
      </>
    );
  };
};

// HOC for class components
export default withAuth;
