import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

class AppmakerAssetChooser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appmakerAssetTemplates: [
                {
                    id: 'app-icon',
                    title: 'App Icon',
                    url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/3c4ac7f7-app-icon.png'
                },
                {
                    id: 'drawer-header',
                    title: 'Drawer header',
                    url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/07281cfa-menu-header.png'
                },
                {
                    id: 'spalsh-logo',
                    title: 'Splash screen Logo',
                    url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/7433a668-splash.png'
                },
                {
                    id: 'login-logo',
                    title: 'Login page logo',
                    url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/e53b1a06-login.png'
                },
                {
                    id: 'push-icon',
                    title: 'Push Notification Icon',
                    url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/9c1406b2-push.png'
                }
            ]
        };
    }
    render() {
        return (
            <div className="bg-dark pt-3">
                <h6 className="text-white ml-3">Select template</h6>
                <div className="template-wrapper">
                    {this.state.appmakerAssetTemplates.map((item) =>
                        <NavLink to={`/design/app-assets/${item.id}`} activeClassName="selected-template-style">
                            <img src={item.url}
                                alt="template"
                                height="100px"
                                className="ml-3"
                                draggable="false"
                                title={item.title}
                            />
                        </NavLink>
                    )}
                </div>
            </div>
        );
    }
}

export default AppmakerAssetChooser;