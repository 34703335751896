// @flow strict

import * as React from "react";
import { saveSvgAsPng } from "save-svg-as-png";
import Form from "react-jsonschema-form";
import AppStoreScreenChooser from "./AppStoreScreenChooser";
import AppStoreScreens from "./appstoreScreens/index";
import { logEvent } from '../../../../lib/analytics';



class AppStoreScreenshot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "Sample",
            appstoreScreenId: "",
            formData: {

            },
        };
    }
    componentDidMount() {
        logEvent('item_viewed', {
            type: 'app-store-asset',
            item_id: this.props.match.params.appstoreScreenId
        })
    }
    formSubmit = () => {
        logEvent('item_saved', {
            type: 'app-store-asset',
            item_id: this.props.match.params.appstoreScreenId
        });
        saveSvgAsPng(document.getElementById("diagram"), "appstore-asset.png", {
            scale: 1
        });
    };
    onChange = form => {
        console.log(form);
        this.setState({
            formData: form.formData
        });
    };

    render() {
        const CurrentAppStoreScreen = AppStoreScreens[this.props.match.params.appstoreScreenId];
        return (
            <React.Fragment>
                <AppStoreScreenChooser />
                <div className="row p-3">
                    <div className="col-md-6 col-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="mb-0">Edit details</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    schema={CurrentAppStoreScreen.formSchema}
                                    onSubmit={this.formSubmit}
                                    onChange={this.onChange}
                                    formData={this.state.formData}
                                >
                                    <button className="btn btn-info" type="submit">Download Image</button>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="card text-center">
                            <div className="card-header bg-dark text-white">
                                <h5 className="mb-0">Preview</h5>
                            </div>
                            <div className="card-body">
                                <CurrentAppStoreScreen id="pattern" {...this.state.formData} />
                            </div>
                            <div class="card-footer">
                                <small class="text-muted">Edit details and click "Download Image" to download as image</small>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AppStoreScreenshot;