import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import * as ROUTES from "./constants/routes";
import ManageWpSites from "./components/manageSite/ManageWpSites";
import SalesDataMain from "./components/appmakerAutomation";
import Playground from "./components/playground";
import PluginSupport from "./components/appmakerAutomation/pluginSupport";
import { FirebaseAuthProvider } from "use-firebase-auth";
import firebase from './lib/firebase';
import DesignTools from "./components/designTools/DesignTools";
import index from "./components";
import ScreenShort from "./components/screenshort";
import FireabaseApp from "./components/firebase";

function App() {
  return (
    <section id="appmaker-internal-tools">
      <FirebaseAuthProvider firebase={firebase}>
        <Router>
          <div className="container-fluid">
            <Route path={ROUTES.SALES_DATA_MAIN} component={SalesDataMain} />
            <Route path={ROUTES.DESIGN_TOOLS} component={DesignTools} />
            <Route path={ROUTES.MANAGE} component={ManageWpSites} />
          </div>
          <Route exact path={ROUTES.INITIAL} component={index} />
          <Route path={"/playground"} component={Playground} />
          <Route path={ROUTES.PLUGIN_SUPPORT} component={PluginSupport} />
          <Route path={ROUTES.ScreenShort} component={ScreenShort} />
          <Route path={'/firebase'} component={FireabaseApp} />
        </Router>
      </FirebaseAuthProvider>
    </section>
  );
}

export default App;
