// @flow strict
import React, { useState, useEffect } from "react";
import Form from "react-jsonschema-form";
import ResponseView from "../ResponseView";
import { api } from "../../../lib/api";
import * as routes from "../../../constants/routes";
import { Link } from "react-router-dom";

const log = type => console.log.bind(console, type);
const form = {
  title: "Plugin list by ProjectId",
  type: "object",
  properties: {
    projectId: { type: "string", title: "Project Id", default: "" }
  }
};

const PluginSupport = props => {
  const [loading, setLoading] = useState(false);
  const [formLoadError, setformLoadError] = useState(false);
  const [response, setResponse] = useState({});
  const [formDataState, setFormData] = useState({});
  const formSubmit = ({ formData }, e) => {
    e.preventDefault();
    setFormData(formData);
  };
  useEffect(() => {
    if (formDataState.projectId) {
      setLoading(true);
      api
        .processPluginList(formDataState.projectId)
        .then(response => {
          // setResponse(response.response)
          setLoading(false);

          setResponse(response);
        })
        .catch(setformLoadError);
    }
  }, [formDataState]);

  const ProjectLinks = () => (
    <>
      <Link
        to={routes.processPlugin(formDataState.projectId || 5731)}
        target="_blank"
      >
        Process plugin list
      </Link>
      <hr />

      <Link
        to={routes.viewPlugin(formDataState.projectId || 5731)}
        target="_blank"
      >
        View PluginList
      </Link>
    </>
  );
  return (
    <div className="p-4">
      <div className="container">
        <Form
          schema={form}
          onSubmit={formSubmit}
          // uiSchema={this.state.uiSchema}
          onError={log("errors")}
        />

        {loading === true ? "submitting..." : null}
        {formLoadError && (
          <div className="alert alert-danger my-3" role="alert">
            Error on loading form
          </div>
        )}
        {form === undefined && (
          <div className="d-block">
            <div class="spinner-grow text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {formDataState.projectId && response.status === "success" && (
          <ProjectLinks />
        )}
        <ResponseView response={JSON.stringify(response, null, 2)} />
      </div>
    </div>
  );
};

export default PluginSupport;
