import React, { useState } from "react";
import { useFirebaseAuth, SIGNIN_PROVIDERS } from "use-firebase-auth";
import firebase from "firebase";

export default function Login() {
  const {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithProvider
  } = useFirebaseAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const LoginWithGoogle = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/cloud-platform")
    provider.addScope("https://www.googleapis.com/auth/analytics.readonly");
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        console.log(token);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };

        fetch(
          "https://firebase.googleapis.com/v1beta1/availableProjects",
          requestOptions
        )
          .then(response => response.text())
          .then(result => console.log(result))
          .catch(error => console.log("error", error));
        // The signed-in user info.
        var user = result.user;
        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
  };

  return (
    <div className="container text-center py-5">
      {/* <input
        type="email"
        className="rounded border border-gray-300 bg-gray-100 text-lg px-4 py-2 w-full"
        placeholder="Email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <input
        type="password"
        className="rounded border border-gray-300 bg-gray-100 text-lg px-4 py-2 w-full mt-2"
        placeholder="Password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <button
        onClick={() => signInWithEmailAndPassword(email, password)}
        className="bg-teal-500 rounded py-2 px-4 text-lg font-semibold text-teal-100 hover:bg-teal-600 mt-2"
      >
        Login
      </button>
      <hr className="border-t border-gray-300 my-4" />

      <input
        type="email"
        className="rounded border border-gray-300 bg-gray-100 text-lg px-4 py-2 w-full"
        placeholder="Email"
        value={registerEmail}
        onChange={e => setRegisterEmail(e.target.value)}
      />
      <input
        type="password"
        className="rounded border border-gray-300 bg-gray-100 text-lg px-4 py-2 w-full mt-2"
        placeholder="Password"
        value={registerPassword}
        onChange={e => setRegisterPassword(e.target.value)}
      />
      <button
        onClick={() =>
          createUserWithEmailAndPassword(registerEmail, registerPassword)
        }
        className="bg-teal-500 rounded py-2 px-4 text-lg font-semibold text-teal-100 hover:bg-teal-600 mt-2"
      >
        Sign Up
      </button> */}
      {/* <hr className="border-t border-gray-300 my-4" /> */}
      <button
        // onClick={() => signInWithProvider(SIGNIN_PROVIDERS.GOOGLE)}
        onClick={() => LoginWithGoogle()}
        className="btn btn-primary"
      >
        <i className="fab fa-google mr-2"></i>Sign in with Google
      </button><br />
      <label className="mt-3">Sign in to continue</label>
    </div>
  );
}

Login.propTypes = {};
