import React, { Component } from 'react';

class ManageWpSites extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment>
                <div className="mt-5 wp-manage">
                    <h1 className="fw-800 header-text">WORDPRESS SITE MANAGER</h1>
                    <h5 className="text-muted">List of generated sites</h5>
                    <table className="table table-sm">
                        <thead>
                            <tr className="bg-dark text-white">
                                <th scope="col">#</th>
                                <th scope="col">Date</th>
                                <th scope="col">Site Name</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1260</th>
                                <td>12/10/2019</td>
                                <td>grocery-demo</td>
                                <td>
                                    <a href="https://appmaker.xyz" className="btn btn-primary btn-sm mr-1">Go to site</a>
                                    <a href="https://appmaker.xyz" className="btn btn-primary btn-sm mr-1">WP Admin</a>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">1260</th>
                                <td>12/10/2019</td>
                                <td>grocery-demo</td>
                                <td>
                                    <a href="https://appmaker.xyz" className="btn btn-primary btn-sm mr-1">Go to site</a>
                                    <a href="https://appmaker.xyz" className="btn btn-primary btn-sm mr-1">WP Admin</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }
}

export default ManageWpSites;