import React from "react";
import SideBar from "./layout/SideBar";
import SalesDataForm from "./layout/SalesDataForm";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as routes from "../../constants/routes";
import PluginSupport from "./localTools/PluginSupport";
import withAuth from "../hoc/withAuth";

export const NavMenu = props => (
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    Internal tools
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <Link className="nav-link" to={routes.INTERNAL}>
            All Tools
          </Link>
        </li>
        <li class="nav-item">
          <Link className="nav-link" to={"/pluginSupport/process-list"}>
            Plugin support - Process List
          </Link>
        </li>
        <li class="nav-item">
          <Link className="nav-link" to={"/pluginSupport/view-list"}>
            Plugin support
          </Link>
        </li>
      </ul>
    </div>
  </nav>
);

function Internal(props) {
  return (
    <div className="h-screen flex flex-col items-center justify-center font-sans">
      <NavMenu />
      <div className="row">
        <div className="col-md-3 p-0">
          <SideBar />
        </div>
        <div className="col-md-9 p-0 tools-main-view over-flow-x">
          {!(
            props.match.params.formId &&
            props.match.params.formId.match("local-")
          ) && <SalesDataForm formId={props.match.params.formId} />}
            <Route
              path={`${routes.INTERNAL}/local-plugin-support`} exact
              component={PluginSupport}
            />
        </div>
      </div>
    </div>
  );
}

export default withAuth(Internal);
