import PlayScreenOne from "./PlayScreenOne";
import PlayScreenTwo from "./PlayScreenTwo";
import PlayScreenThree from "./PlayScreenThree";
import PlayFeatureGraphic from "./PlayFeatureGraphic";
import PlayHighResLogo from "./PlayHighResLogo";
import PlayScreenFour from "./PlaysScreenFour";
import PlayScreenFive from "./PlayScreenFive";

const PlayScreens = {
    3001: PlayScreenOne,
    3002: PlayScreenTwo,
    3003: PlayScreenThree,
    3004: PlayFeatureGraphic,
    3005: PlayHighResLogo,
    3006: PlayScreenFour,
    3007: PlayScreenFive
}
export default PlayScreens;