import * as React from "react";

const AppStoreScreenThree = ({ image, title1, bgcolor, txtcolor }) => {
    return (
        <svg
            id="diagram"
            width="450" height="auto" viewBox="0 0 2048 2732"
            className="shadow-lg"
        >
            <defs>
                <pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 1080 2160">
                    <image preserveAspectRatio="none" width="1080" height="2160" href={image || "https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/82dec4d1-home-ipad-ios-7.jpg"} />
                </pattern>
            </defs>
            <g id="ipad-nodevice" transform="translate(-263 -18552)">
                <rect id="Rectangle_130" data-name="Rectangle 130" width="2048" height="2732" transform="translate(263 18552)" fill={bgcolor} />
                <g id="Screenshot_20191010_095729_com.appilder.woocommerce" transform="translate(563 19075)" fill="url(#pattern)">
                    <rect width="1450" height="1925" rx="20" stroke="none" />
                    <rect x="0.5" y="0.5" width="1449" height="1924" rx="19.5" fill="none" />
                </g>
                <text id="Any_Feature" data-name="Any Feature" transform="translate(563 18730)" font-size="85" font-family="Poppins-Regular, Poppins" fill={txtcolor} textAnchor="middle"><tspan x="710" y="89">{title1}</tspan></text>
            </g>
        </svg>
    );
};
AppStoreScreenThree.formSchema = {
    type: "object",
    title: "iPad Without device (12.9\" Display)",
    properties: {
        image: {
            type: "string",
            format: "data-url",
            title: "Screenshot"
        },
        title1: {
            type: "string",
            title: "Main title",
            default: "Any Feature Here"
        },
        bgcolor: {
            type: "string",
            title: "Background color",
            default: "#fff"
        },
        txtcolor: {
            type: "string",
            title: "Text color",
            default: "#000"
        }
    }
};
export default AppStoreScreenThree;
