import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

class GridImageChooser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gridImageTemplates: [
                {
                    id: 2001,
                    url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/434c19b5-grid-1.png'
                },
                {
                    id: 2002,
                    url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/dbafffbe-grid-2.png'
                },
                {
                    id: 2003,
                    url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/20ecbe67-grid-3.png'
                }
            ]
        };
    }
    render() {
        return (
            <div className="bg-dark pt-3">
                <h6 className="text-white ml-3">Select template</h6>
                <div className="template-wrapper">
                    {this.state.gridImageTemplates.map((item) =>
                        <NavLink to={`/design/grid/${item.id}`} activeClassName="selected-template-style">
                            <img src={item.url}
                                alt="grid template"
                                width="100px"
                                className="ml-3"
                            />
                        </NavLink>
                    )}
                </div>
            </div>
        );
    }
}

export default GridImageChooser;