// @flow strict

import * as React from "react";
import { saveSvgAsPng } from "save-svg-as-png";
import Form from "react-jsonschema-form";
import AppmakerAssets from "./assets/index";
import AppmakerAssetChooser from "./AppmakerAssetChooser";
import { logEvent } from '../../../lib/analytics';

class AppmakerAsset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assetId: "",
            formData: {

            },
        };
    }
    componentDidMount() {
        logEvent('item_viewed', {
            type: 'asset-image',
            item_id: this.props.match.params.assetId
        })
    }
    formSubmit = () => {
        logEvent('item_saved', {
            type: 'asset-image',
            item_id: this.props.match.params.assetId
        });
        saveSvgAsPng(document.getElementById("diagram"), "appmaker-asset.png", {
            scale: 1
        });
    };
    onChange = form => {
        console.log(form);
        this.setState({
            formData: form.formData
        });
    };

    render() {
        const CurrentAppmakerAsset = AppmakerAssets[this.props.match.params.assetId];
        console.log(CurrentAppmakerAsset);
        return (
            <React.Fragment>
                <AppmakerAssetChooser />
                <div className="row p-3">
                    <div className="col-md-6 col-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="mb-0">Edit Asset Details</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    schema={CurrentAppmakerAsset.formSchema}
                                    uiSchema={CurrentAppmakerAsset.uiSchema}
                                    onSubmit={this.formSubmit}
                                    onChange={this.onChange}
                                    formData={this.state.formData}
                                >
                                    <button className="btn btn-info" type="submit">Download Image</button>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="card text-center">
                            <div className="card-header bg-dark text-white">
                                <h5 className="mb-0">Preview</h5>
                            </div>
                            <div className="card-body">
                                <CurrentAppmakerAsset id="pattern" {...this.state.formData} />
                            </div>
                            <div className="card-footer">
                                <small className="text-muted">Edit details and click "Download Image" to download as image</small>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AppmakerAsset;