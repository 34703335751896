// @flow strict

import * as React from "react";

import { JsonToTable } from "react-json-to-table";

class ResponseView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: "preview"
    };
  }
  render() {
    let jsonResponse;
    try {
      jsonResponse = JSON.parse(this.props.response);
    } catch (error) {}
    return (
      <div className="row mt-3">
        <div className="col-10 ">
          {this.props.response && (
            <div class="btn-group " role="group" aria-label="Basic example">
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => this.setState({ viewType: "preview" })}
              >
                Preview
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => this.setState({ viewType: "raw" })}
              >
                Raw
              </button>
            </div>
          )}
          <div className="mt-3">
            {jsonResponse ? (
              this.state.viewType === "preview" ? (
                <JsonToTable json={jsonResponse} />
              ) : (
                <code>
                  <pre>{this.props.response}</pre>
                </code>
              )
            ) : (
              <code>
                <pre>{this.props.response}</pre>
              </code>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ResponseView;
