import * as React from "react";

const GridOne = ({ image, title1 }) => {
    return (
        <svg id="diagram" width="150" height="175" viewBox="0 0 300 350">
            <defs>
                <pattern id="pattern" preserveAspectRatio="xMidYMid slice" width="100%" height="100%" viewBox="0 0 300 350">
                    <image preserveAspectRatio="xMidYMid slice" width="300" height="350" href={image || 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/c97cb4f6-91d7b80f-mariya-georgieva-0o6fv3ff_xi-unsplash-944x1024.jpg'} />
                </pattern>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#303030" stop-opacity="0" />
                    <stop offset="1" stop-color="#303030" />
                </linearGradient>
            </defs>
            <g id="grid-1" transform="translate(-6684 -2836)">
                <rect id="mariya-georgieva-0O6Fv3Ff_XI-unsplash" width="300" height="350" transform="translate(6684 2836)" fill="url(#pattern)" />
                <rect id="gradient-overlay" width="300" height="350" transform="translate(6684 2836)" fill="url(#linear-gradient)" />
                <text id="MALE" transform="translate(6712 3119)" fill="#fff" font-size="30" font-family="Poppins-SemiBold, Poppins" font-weight="600" text-anchor="middle"><tspan x="125" y="32">{title1}</tspan></text>
            </g>
        </svg>
    );
};
GridOne.formSchema = {
    type: "object",

    properties: {
        image: {
            type: "string",
            format: "data-url",
            title: "Background image"
        },
        title1: {
            type: "string",
            title: "Main title",
            default: "MEN"
        }
    }
};
export default GridOne;