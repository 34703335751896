import { db } from "./firebase";

const COLLECTION_NAME = "pluginsList";
const hasPlugin = id =>
  db
    .collection(COLLECTION_NAME)
    .where("id", "==", id)
    .get()
    .then(snapshot => {
      if (snapshot.empty) {
        return false;
      }

      return true;
    });
const getPluginDetails = id =>
  db
    .collection(COLLECTION_NAME)
    .where("id", "==", id)
    .get()
    .then(snapshot => {
      if (snapshot.empty) {
        return false;
      }
      let document;
      snapshot.forEach(doc => {
        document = doc;
      });
      return document;
    });
const getAllPluginList = () =>
  db
    .collection(COLLECTION_NAME)
    .get()
    .then(snapshot => {
      if (snapshot.empty) {
        return false;
      }
      const documents = [];
      snapshot.forEach(doc => {
        documents.push(doc.data());
      });
      return documents;
    });
const getAllPluginListByStatus = status =>
  db
    .collection(COLLECTION_NAME)
    .where("appmakerPluginStatus", "==", status)
    .get()
    .then(snapshot => {
      if (snapshot.empty) {
        return false;
      }
      const documents = [];
      snapshot.forEach(doc => {
        documents.push(doc.data());
      });
      return documents;
    });

const addToPluginList = data =>
  hasPlugin(data.id).then(pluginExist => {
    if (pluginExist) return Promise.reject("Plugin Exist");
    else
      return db
        .collection(COLLECTION_NAME)
        .add({ appmakerPluginStatus: "to-process", ...data });
  });
const createOrUpdatePluginSupport = async ({ pluginData, status }) => {
  const pluginDetails = await getPluginDetails(pluginData.id);
  if (pluginDetails) {
    return updatePluginSupportStatus({ documentId: pluginDetails.id, status });
  } else {
    return addToPluginList(pluginData).then(item =>
      updatePluginSupportStatus({ documentId: item.id, status })
    );
  }
};
const updatePluginSupportStatus = ({ documentId, status }) =>
  db
    .collection(COLLECTION_NAME)
    .doc(documentId)
    .update({
      appmakerPluginStatus: status
    });
export const pluginListListener = status =>
  db
    .collection(COLLECTION_NAME)
    .limit(100)
    .where("appmakerPluginStatus", "==", status);
export const pluginListListenerForProject = (status, projectId) =>
  db
    .collection(COLLECTION_NAME)
    // .limit(10)
    .where("appmakerPluginStatus", "==", status)
    .where("installed_project_id", "array-contains", projectId);
export const getAllPluginListInStatus = status =>
  db
    .collection(COLLECTION_NAME)
    // .limit(10)
    .where("appmakerPluginStatus", "in", status)
    .get()
    .then(snapshot => {
      if (snapshot.empty) {
        return false;
      }
      const documents = [];
      snapshot.forEach(doc => {
        documents.push(doc.data());
      });
      return documents;
    });
export {
  addToPluginList,
  hasPlugin,
  getAllPluginList,
  updatePluginSupportStatus,
  getPluginDetails,
  createOrUpdatePluginSupport,
  getAllPluginListByStatus
};
