import * as React from "react";

const AppStoreHighResLogo = ({ icon, bgcolor, check }) => {
    function Logo1() {
        return (
            <g id="appstore-high-resolution-logo-1" transform="translate(1809 -5459)">
                <rect id="main-container" width="1024" height="1024" transform="translate(-1809 5459)" fill="#fff" />
                <rect id="image" width="1024" height="1024" transform="translate(-1809 5459)" fill="url(#pattern)" />
            </g>
        );
    };
    function Logo2() {
        return (
            <g id="appstore-high-resolution-logo" transform="translate(1809 -5459)">
                <rect id="main-container" width="1024" height="1024" transform="translate(-1809 5459)" fill="#fff" />
                <rect id="image" width="912" height="912" transform="translate(-1753 5515)" fill="url(#pattern)" />
            </g>
        );
    };
    return (
        <svg
            id="diagram"
            width="200"
            height="200"
            viewBox="0 0 1024 1024"
            className="shadow-lg"
        >
            <defs>
                <pattern id="pattern" preserveAspectRatio="xMidYMid slice" width="100%" height="100%" viewBox="0 0 406 403">
                    <image width="406" height="403" href={icon || 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/08/4e401062-logo.png'} />
                </pattern>
            </defs>
            {check === true ? <Logo1 /> : <Logo2 />}
        </svg>
    );
};
AppStoreHighResLogo.formSchema = {
    type: "object",
    title: "High Resolution Logo (1024x1024 px)",
    properties: {
        icon: {
            type: "string",
            format: "data-url",
            title: "Logo"
        },
        bgcolor: {
            type: "string",
            title: "Background color",
            default: "#fff"
        },
        check: {
            type: "boolean",
            title: "   Remove",
            description: "Remove space arround"
        }
    }
};
export default AppStoreHighResLogo;
