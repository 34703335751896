import React, { Component } from "react";
import Form from "react-jsonschema-form";
import { proxyFetch, api } from "../../../lib/api";
import querystring from "querystring";
import ResponseView from "../ResponseView";
const log = type => console.log.bind(console, type);

class SalesDataForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      formLoadError: false,
      response: ""
    };
  }

  formSubmit = ({ formData }, e) => {
    this.setState({ loading: true });
    const processResponse = data => {
      try {
        const jsonReponse = JSON.stringify(JSON.parse(data), null, 2);
        this.setState({
          loading: false,
          response: jsonReponse
        });
      } catch (error) {
        this.setState({
          loading: false,
          response: data
        });
      }
    };
    if (this.state.form.submitConfig.method === "POST") {
      proxyFetch(this.state.form.submitConfig.url, {
        method: this.state.form.submitConfig.method,
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      })
        .then(res => res.text())
        .then(processResponse);
    } else if (this.state.form.submitConfig.method === "GET") {
      proxyFetch(
        `${this.state.form.submitConfig.url}?${querystring.stringify(
          formData
        )}`,
        {
          method: this.state.form.submitConfig.method,
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
        .then(res => res.text())
        .then(processResponse);
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.formId !== this.props.formId) this.loadData();
  }

  loadData = () => {
    this.setState({
      loading: false,
      formLoadError: false,
      form: undefined,
      response: ""
    });
    api
      .formById(this.props.formId)
      .then(resp => {
        this.setState({
          form: JSON.parse(resp.data.formSchema),
          uiSchema: (resp.data.uiSchema && JSON.parse(resp.data.uiSchema)) || {}
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ formLoadError: true, loading: false });
      });
  };
  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div className="p-4">
        <div className="container">
          {this.state.form && (
            <Form
              schema={this.state.form}
              onSubmit={this.formSubmit}
              uiSchema={this.state.uiSchema}
              onError={log("errors")}
            />
          )}
          {this.state.loading === true ? "submitting..." : null}
          {this.state.formLoadError && (
            <div className="alert alert-danger my-3" role="alert">
              Error on loading form
            </div>
          )}
          {this.state.form === undefined && (
            <div className="d-block">
              <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}

          <ResponseView response={this.state.response} />
        </div>
      </div>
    );
  }
}

export default SalesDataForm;
