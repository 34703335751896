import React, { useState, useEffect } from "react";
import { api } from "../../../lib/api";
import { NavLink } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import { useFirebaseAuth } from "use-firebase-auth";
const localMenu = [{ id: "local-plugin-support", title: "Plugin Support" }];
const SideBar = props => {
  const { signOut } = useFirebaseAuth();

  const [menu, setMenu] = useState(localMenu);
  useEffect(() => {
    api.formList().then(data => {
      setMenu([...data.data, ...localMenu]);
    });
  }, []);
  const menuItems = menu.map(item => (
    <NavLink
      to={`${ROUTES.SALES_DATA}/${item.id}`}
      className="btn btn-block text-left"
      activeClassName="btn-dark"
    >
      {item.title}
    </NavLink>
  ));
  return (
    <div className="bg-light p-4 vh-100">
      {menuItems}
      <button className="btn btn-primary btn-block mt-4" onClick={signOut}>
        Logout
      </button>
    </div>
  );
};
export default SideBar;
