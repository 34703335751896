const options = {
  YES: {
    label: "Yes",
    value: "yes"
  },
  NO: { label: "No", value: "no" },
  DontKnow: { label: "Don't Know", value: "dont-know" },
  NotRelated: { label: "Not Related", value: "not-related" },
  NeedToCheck: { label: "Need to check", value: "need-to-check" }
};
export default options;
