import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

const availableTemplates = [
    {
        id: 3005,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/11/447d0aa7-high-resolution-logo.png'
    },
    {
        id: 3001,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/75923b48-screenshot-playstore-1.png'
    },
    {
        id: 3002,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/854159ff-screenshot-playstore-2.png'
    },
    {
        id: 3003,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/9f4027ba-screenshot-playstore-3.png'
    },
    {
        id: 3006,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/11/1c5c7178-screenshot-playstore-logo.png'
    },
    {
        id: 3007,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/11/66521072-play-screenshot-plane.png'
    },
    {
        id: 3004,
        url: 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/422161bb-feature-graphic.png'
    }
]

const mandatoryId = [3005, 3007, 3004];

class PlayScreenChooser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false,
            PlayScreenTemplates: []
        };
    }
    toggleChange = () => {
        if (!this.state.isChecked) {
            const ImagesArray = [];
            mandatoryId.forEach(id => {
                availableTemplates.forEach(element => {
                    if (element.id === id) {
                        ImagesArray.push(element);
                    }
                })
            })
            this.setState({ PlayScreenTemplates: ImagesArray, isChecked: !this.state.isChecked })

        }
        else {
            this.setState({ PlayScreenTemplates: availableTemplates, isChecked: !this.state.isChecked })
        }
    }
    componentDidMount() {
        this.setState({ PlayScreenTemplates: availableTemplates })
    }
    render() {
        const generateTemplate = this.state.PlayScreenTemplates.map((item) => {
            return (
                <NavLink to={`/design/playscreen/${item.id}`} activeClassName="selected-template-style">
                    <img src={item.url}
                        alt="grid template"
                        height="100px"
                        className="ml-3"
                    />
                </NavLink>
            );
        })
        return (
            <div className="bg-dark pt-3">
                <h5 className="text-white ml-3">Select template</h5>
                <div className="custom-control custom-switch my-2 ml-3 text-white">
                    <input type="checkbox" className="custom-control-input" id="mandatorySwitch" checked={this.state.isChecked} onChange={this.toggleChange} />
                    <label className="custom-control-label" for="mandatorySwitch">Show mandatory only</label>
                </div>
                <div className="template-wrapper">
                    {generateTemplate}
                </div>
            </div>
        );
    }
}

export default PlayScreenChooser;