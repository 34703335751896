import * as React from "react";

const IpadLogo = ({ image, logo, bgcolor }) => {
    return (
        <svg
            id="diagram"
            width="450" height="auto" viewBox="0 0 2048 2732"
            className="shadow-lg"
        >
            <defs>
                <pattern id="pattern" width="100%" height="100%" viewBox="0 0 768 1024">
                    <image preserveAspectRatio="none" width="768" height="1024" href={image || "https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/10/82dec4d1-home-ipad-ios-7.jpg"} />
                </pattern>
                <filter id="Home-iPad-iOS-7" x="277.5" y="514.5" width="1495" height="1970" filterUnits="userSpaceOnUse">
                    <feOffset dy="14" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="7.5" result="blur" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <pattern id="pattern-2" width="1" height="1" viewBox="-4.673 -25.403 834.422 223.806">
                    <image preserveAspectRatio="xMidYMid meet" width="825.077" height="173" href={logo || 'https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/11/2c567bea-appmaker-logo.png'} />
                </pattern>
            </defs>
            <g id="ipad-logo-noframe" transform="translate(3786 -13604)">
                <rect id="ipad-bg" width="2048" height="2732" transform="translate(-3786 13604)" fill={bgcolor} />
                <g transform="matrix(1, 0, 0, 1, -3786, 13604)" filter="url(#Home-iPad-iOS-7)">
                    <rect id="Home-iPad-iOS-7-2" data-name="Home-iPad-iOS-7" width="1450" height="1925" rx="20" transform="translate(300 523)" fill="url(#pattern)" />
                </g>
                <rect id="Asset_2_4x" data-name="Asset 2@4x" width="645" height="173" transform="translate(-3084 13791)" fill="url(#pattern-2)" />
            </g>
        </svg>
    );
};
IpadLogo.formSchema = {
    type: "object",
    title: "iPad Without device (12.9\" Display)",
    properties: {
        image: {
            type: "string",
            format: "data-url",
            title: "Screenshot"
        },
        logo: {
            type: "string",
            format: "data-url",
            title: "Logo"
        },
        bgcolor: {
            type: "string",
            title: "Background color",
            default: "#fff"
        }
    }
};
export default IpadLogo;
