import React, { useState, useEffect } from "react";

import PluginItem from "../pluginSupport/components/PluginItem";
import {
  pluginListListener,
  pluginListListenerForProject
} from "../../../lib/pluginListHelper";

const ProcessList = props => {
  const [isLoaded, setLoaded] = useState(false);
  const [pluginList, setPluginList] = useState([]);
  const { status, projectId } = props.match.params;
  useEffect(() => {
    const listner = projectId
      ? pluginListListenerForProject(status || "to-process", projectId)
      : pluginListListener(status || "to-process");
    listner.onSnapshot(querySnapshot => {
      var docs = [];
      querySnapshot.forEach(doc => {
        docs.push(doc.data());
      });
      setPluginList(docs);
      setLoaded(true);
    });
  }, [status]);
  return (
    <div className="p-4 row">
      <h5>
        <b>{isLoaded && pluginList.length}</b> Plugins to process
      </h5>
      {isLoaded && (
        <div className="card-columns">
          {pluginList.map((item, key) => {
            return <PluginItem key={`sample-${key}`} data={item} />;
          })}
        </div>
      )}
      {isLoaded && pluginList.length === 0 && "No items to process"}
      {!isLoaded && "Loading ..."}
    </div>
  );
};
export default ProcessList;
