import * as React from "react";

const PushIcon = ({ image, pushbgcolor }) => {
    return (
        <svg id="diagram" width="80" height="80" viewBox="0 0 80 80">
            <defs>
                <pattern id="pattern" preserveAspectRatio="xMidYMid slice" width="100%" height="100%" viewBox="0 0 406 403">
                    <image width="406" height="403" href={image || "https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/08/4e401062-logo.png"} />
                </pattern>
            </defs>
            <g id="push-notification-icon" transform="translate(7470 -2769)">
                <circle id="push-notification-icon-2" data-name="push-notification-icon" cx="40" cy="40" r="40" transform="translate(-7470 2769)" fill={pushbgcolor} />
                <rect id="logo_container" width="56" height="56" transform="translate(-7458 2781)" fill="url(#pattern)" />
            </g>
        </svg>
    );
};
PushIcon.formSchema = {
    type: "object",
    title: "Push-notification Icon",
    properties: {
        image: {
            type: "string",
            format: "data-url",
            title: "Logo/Icon"
        },
        pushbgcolor: {
            type: "string",
            title: "Background color",
            default: "#f5f5f5"
        }
    }
};
export default PushIcon;