// @flow strict

import React, { useState, useEffect } from "react";

// import { JsonToTable } from "react-json-to-table";

import data from "./data.json";
import PluginItem from "../appmakerAutomation/pluginSupport/components/PluginItem";
import { getAllPluginList } from "../../lib/pluginListHelper";
// class Playground extends React.Component {
//   render() {
//     return (
//       <div className="p-4 row">
//         <div className="card-columns">
//           {data.map((item, key) => (
//             <PluginItem key={`sample-${key}`} data={item} />
//           ))}
//         </div>
//       </div>
//     );
//   }
// }
const Playground = props => {
  const [isLoaded, setLoaded] = useState(false);
  const [pluginList, setPluginList] = useState([]);
  useEffect(() => {
    getAllPluginList().then(resp => {
      setPluginList(resp);
      setLoaded(true);
    });
    // setLoaded(true)
  }, []);
  console.log(pluginList);
  return (
    <div className="p-4 row">
      {isLoaded && (
        <div className="card-columns">
          {pluginList.map((item, key) => (
            <PluginItem key={`sample-${key}`} data={item} />
          ))}
        </div>
      )}
      {!isLoaded && "Loading ..."}
    </div>
  );
};
export default Playground;
